import React, { useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Typography,
  Grid,
  Button,
  IconButton,
  FormHelperText,
  Avatar,
  Badge,
  TextField,
  Box,
  CircularProgress
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { addCategorySchema } from "../../../schema/index";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import EditIcon from "@mui/icons-material/Edit";
import { putAPIHandler } from "../../../config/service";
import { fileUploadHandler } from "../../../utils/fileUploadHandler";
import toast from "react-hot-toast";
import { transliterateToHindi } from "../../../config/service";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const EditArticleCategory = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const imageFieldRef = useRef();
  const [imageResolutionError, setImageResolutionError] = useState("");
  const { state } = useLocation();
  console.log(state, "edit category");
  const thumbnailImageEnglish = state?.value?.thumbnail_en;
  const thumbnailImageHindi = state?.value?.thumbnail_hi;
  const [isImageLoading_en, setIsImageLoading_en] = useState(false); // Loader for English image
  const [isImageLoading_hi, setIsImageLoading_hi] = useState(false);

  const onSubmit = async (values, { resetForm }) => {
    console.log(values, "values");
    const payloadToSend = {
      categoryId: state?.value?.id,
      categoryName_en: values.categoryName,
      categoryName_hi: values.categoryNameHindi,
      thumbnail_en: values.thumbnailImage_en,
      thumbnail_hi: values.thumbnailImage_hi,
    };
    try {
      const response = await putAPIHandler({
        endPoint: "updateContentCategory",
        dataToSend: payloadToSend,
      });
      console.log(response, "response");

      if (response && response.data.responseCode === 200) {
        toast.success(response.data.responseMessage);
        navigate("/informative-content");
      } else {
        console.error("error.");
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const fileUploader = async (
    event,
    setFieldValue,
    fieldName,
    setLoadingState
  ) => {
    setLoadingState(true); // Start loader
    const file = event.target.files[0];
    if (!file) {
      setImageResolutionError("");
      setFieldValue(fieldName, null);
      setLoadingState(false); // Stop loader
      return;
    }

     // Validate file type
     if (
      !["image/jpeg", "image/png", "image/webp",].includes(file.type)
    ) {
      setImageResolutionError(
        "Unsupported file type. Please upload a JPG, PNG, WebP image."
      );
      setIsLoading(false);
      return;
    }

    // Validate file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setImageResolutionError(
        "The file is too large. Please upload a file smaller than 2MB."
      );
      setLoadingState(false); // Stop loader
      return;
    }

    const img = new Image();
    const reader = new FileReader();

    reader.onloadend = async () => {
      img.onload = async () => {
        // Validate image resolution
        if (img.width < 180 || img.height < 180) {
          setImageResolutionError("Image must be at least 180px x 180px.");
          setLoadingState(false); // Stop loader
          return;
        }

        setImageResolutionError("");
        setImage(reader.result); // Set image for preview

        try {
          const uploadedUrl = await fileUploadHandler(file);
          if (uploadedUrl) {
            setFieldValue(fieldName, uploadedUrl);
          } else {
            setImageResolutionError(
              "Failed to upload image. Please try again."
            );
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingState(false); // Stop loader
        }
      };
      img.src = reader.result;
    };

    reader.readAsDataURL(file);
  };
  const isAddButtonDisabled = isImageLoading_en || isImageLoading_hi;

  return (
    <Grid container justifyContent={"center"}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0px 8%",
        }}
        item
        xs={12}
        mb={2}
      >
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: "#2A73E0",
          }}
        >
          <ArrowCircleLeftIcon
            sx={{
              fontSize: "30px",
            }}
          />
        </IconButton>
        <Typography color={"#262626"} fontWeight={"600"} variant="h5">
          Edit Category
        </Typography>
      </Grid>

      <Grid item container xs={12}>
        <Formik
          initialValues={{
            categoryName: state?.value?.categoryName_en || " ",
            categoryNameHindi: state?.value?.categoryName_hi || " ",
            thumbnailImage_en: state?.value?.thumbnail_en || null,
            thumbnailImage_hi: state?.value?.thumbnail_hi || null,
          }}
          validationSchema={addCategorySchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form
            style={{
              minWidth: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              item
              // sm={8}
              md={10}
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                p: "10px",
              }}
            >
              <Grid
                container
                item
                xs={12}
                sx={{
                  gap: { sm: 1, lg: 6 },
                  justifyContent: { sm: "flex-start", md: "center" },
                }}
                mt={2}
              >
                {/* Thumbnail Image Label */}
                <Grid container item sm={12} lg={2}>
                  <Typography variant="body1" sx={{ whiteSpace: "pre-line"  }}>
                    Thumbnail (थंबनेल):
                  </Typography>
                </Grid>

                {/* First Image Upload */}
                <Grid
                  container
                  item
                  sm={12}
                  lg={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) =>
                        fileUploader(
                          e,
                          setFieldValue,
                          "thumbnailImage_en",
                          setIsImageLoading_en
                        )
                      }
                      style={{ display: "none" }}
                      id="thumbnailImage_en"
                    />
                    <label htmlFor="thumbnailImage_en">
                      <Button
                        variant="outlined"
                        component="span"
                        sx={{
                          width: "100%",
                          padding: "12px 20px",
                          borderRadius: "8px",
                          borderColor: "#ccc",
                          textTransform: "none",
                          color: "#333",
                          "&:hover": {
                            backgroundColor: "#f4f4f4",
                            borderColor: "#bbb",
                          },
                        }}
                        startIcon={<PhotoCameraIcon />}
                      >
                        Upload Image
                      </Button>
                    </label>

                    {errors.thumbnailImage_en &&
                      touched.thumbnailImage_en && (
                        <FormHelperText error>
                          {errors.thumbnailImage_en}
                        </FormHelperText>
                      )}

                    {/* Loader (Spinner) */}
                    {isImageLoading_en ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      // Reserve space for image preview
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: values.thumbnailImage_en ? "100px" : "0",
                          width: "100%",
                          mt: 1,
                        }}
                      >
                        {values.thumbnailImage_en && (
                          <Avatar
                            alt="Image Preview"
                            src={values.thumbnailImage_en}
                            sx={{
                              width: 100,
                              height: 100,
                              borderRadius: "8px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                              transition: "transform 0.2s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.1)",
                                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                              },
                            }}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </Grid>

                {/* Second Image Upload */}
                <Grid
                  container
                  item
                  sm={12}
                  lg={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) =>
                        fileUploader(
                          e,
                          setFieldValue,
                          "thumbnailImage_hi",
                          setIsImageLoading_hi
                        )
                      }
                      style={{ display: "none" }}
                      id="thumbnailImage_hi"
                    />
                    <label htmlFor="thumbnailImage_hi">
                      <Button
                        variant="outlined"
                        component="span"
                        sx={{
                          width: "100%",
                          padding: "12px 20px",
                          borderRadius: "8px",

                          textTransform: "none",
                          color: "#333",
                          "&:hover": {
                            backgroundColor: "#f4f4f4",
                            borderColor: "#bbb",
                          },
                        }}
                        startIcon={<PhotoCameraIcon />}
                      >
                        चित्र अपलोड करें
                      </Button>
                    </label>

                    {errors.thumbnailImage_hi &&
                      touched.thumbnailImage_hi && (
                        <FormHelperText error>
                          {errors.thumbnailImage_hi}
                        </FormHelperText>
                      )}

                    {isImageLoading_hi ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      // Reserve space for image preview
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: values.thumbnailImage_hi ? "100px" : "0",
                          width: "100%",
                          mt: 1,
                        }}
                      >
                        {values.thumbnailImage_hi && (
                          <Avatar
                            alt="Image Preview"
                            src={values.thumbnailImage_hi}
                            sx={{
                              width: 100,
                              height: 100,
                              borderRadius: "8px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                              transition: "transform 0.2s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.1)",
                                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                              },
                            }}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                sx={{
                  gap: { sm: 1, lg: 6 },
                  justifyContent: { sm: "flex-start", md: "center" },
                }}
                mt={2}
              >
                {/* Category Name Input */}
                <Grid container item sm={12} lg={2}>
                  <Typography variant="body1" sx={{ whiteSpace: "pre-line"  }}>
                    Category (श्रेणी):
                  </Typography>
                </Grid>

                <Grid container item sm={12} lg={4}>
                  <TextField
                    fullWidth
                    id="categoryName"
                    name="categoryName"
                    value={values.categoryName}
                    onChange={(e) => {
                      const englishText = e.target.value;
                      handleChange(e);

                      // Transliterate to Hindi
                      transliterateToHindi(englishText).then((hindiText) => {
                        setFieldValue("categoryNameHindi", hindiText);
                      });
                    }}
                    onBlur={handleBlur}
                    placeholder="Enter category name"
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "12px 20px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        backgroundColor: "#fff",
                      },
                      "& .MuiInputBase-root:hover": {
                        backgroundColor: "#f4f4f4",
                        borderColor: "#bbb",
                      },
                      "& .Mui-focused": {
                        borderColor: "rgb(60,115,224)",
                      },
                      "& input": {
                        padding: 0, // Adjust if necessary
                      },
                    }}
                    error={
                      touched.categoryName && Boolean(errors.categoryName)
                    }
                    helperText={touched.categoryName && errors.categoryName}
                  />
                </Grid>

                {/* Hindi Category Name Input */}
                <Grid container item sm={12} lg={4}>
                  <TextField
                    fullWidth
                    id="categoryNameHindi"
                    name="categoryNameHindi"
                    value={values.categoryNameHindi}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="श्रेणी नाम दर्ज करें"
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "12px 20px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        backgroundColor: "#fff",
                      },
                      "& .MuiInputBase-root:hover": {
                        backgroundColor: "#f4f4f4",
                        borderColor: "#bbb",
                      },
                      "& .Mui-focused": {
                        borderColor: "rgb(60,115,224)",
                      },
                      "& input": {
                        padding: 0, // Adjust if necessary
                      },
                    }}
                    error={
                      touched.categoryNameHindi &&
                      Boolean(errors.categoryNameHindi)
                    }
                    helperText={
                      touched.categoryNameHindi && errors.categoryNameHindi
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                justifyContent={"center"}
                mt={6}
                mb={8}
                sx={{ gap: { xs: 4, md: 10 } }}
              >
                <Grid item container xs={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ background: "gray" }}
                    onClick={() => {
                      navigate(-1);
                    }}
                    disabled={isLoading}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item container xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isAddButtonDisabled}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default EditArticleCategory;
