import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { postAPIHandler, putAPIHandler } from "../../../../../config/service";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { transliterateToHindi } from "../../../../../config/service";

const AssessmentType = {
  ADD: "ADD",
  EDIT: "EDIT",
  VIEW: "VIEW",
};

const typeOfFieldData = [
  {
    type: "DROPDOWN",
    status: true,
  },
  {
    type: "CHECKBOX",
    status: false,
  },
  {
    type: "TEXTAREA",
    status: false,
  },
  {
    type: "RADIO",
    status: false,
  },
  {
    type: "TEXTFIELD",
    status: false,
  },
];

const ValidationSchema = Yup.object({
  question: Yup.string()
    .required("Question is required.")
    .trim()
    .min(5, "Minimum 5 character are required."),
  questionHindi: Yup.string()
    .required("हिंदी प्रश्न आवश्यक है")
    .trim()
    .min(5, "कम से कम 5 वर्ण आवश्यक हैं।"),
});

const AddEditQuestion = ({
  open,
  setOpen,
  type,
  data,
  setisUpdated,
  onSuccess,
}) => {
  const { id } = useParams();
  const [typefield, setTypefield] = useState(typeOfFieldData);
  const [isLoading, setisLoading] = useState(false);
  const [questionType, setQuestionType] = useState("");

  const [field, setField] = useState([
    {
      type: typefield.filter((e, i) => e.status == true)[0].type,
      id: 0,
      value: "",
      valueHindi: "",
    },
  ]);
  console.log("field: ", field);

  useEffect(() => {
    if (AssessmentType.ADD == type) {
      setField([
        {
          type: typefield.filter((e, i) => e.status == true)[0]?.type,
          id: 0,
          value: "",
          valueHindi: "",
        },
      ]);
    } else {
      editQuestionLoader();
    }
  }, [data]);

  const editQuestionLoader = async () => {
    const category = data?.answerType;
    setQuestionType(category);
    const typeFieldUpdated = typeOfFieldData;
    setTypefield(
      typeFieldUpdated.map((e, i) => {
        if (e.type == category) {
          e.status = true;
          return e;
        }
        e.status = false;
        return e;
      })
    );
    let fieldToBeEdited = data?.answerOptions_en?.map((e, i) => {
      return {
        type: category,
        id: i,
        value: e,
        valueHindi: data?.answerOptions_hi?.[i] || "",
      };
    });
    setField(fieldToBeEdited);
  };

  const updateQuestionHandler = async (values) => {
    if (questionType == "CHECKBOX") {
      if (field.length < 2) {
        toast.error("At least two options are needed");
        setisLoading(false);
        return;
      } else {
        if (
          field.some((e) => e.value.trim() == "" || e.valueHindi.trim() == "")
        ) {
          toast.error("Options cannot be empty in either language");
          setisLoading(false);
          return;
        }
      }
    }
    let questionTobeSend_en =
      questionType == "TEXTAREA" || questionType === "TEXTBAR"
        ? []
        : field.filter((e) => e.value.trim() != "").map((e) => e.value?.trim());

    let questionTobeSend_hi =
      questionType == "TEXTAREA" || questionType === "TEXTBAR"
        ? []
        : field
            .filter((e) => e.valueHindi.trim() != "")
            .map((e) => e.valueHindi?.trim());

    setisLoading(true);

    const payloadToSend = {
      questionnaireId: id,
      questionId: data?.id,
      question_en: values?.question?.trim(),
      question_hi: values?.questionHindi?.trim(),
      answerType: field[0]?.type ? field[0]?.type : questionType,
      answerOptions_en: questionTobeSend_en,
      answerOptions_hi: questionTobeSend_hi,
    };

    const res = await putAPIHandler({
      endPoint: "updateQuestionnaireQuestion",
      dataToSend: payloadToSend,
    });

    if (!res) {
      setisLoading(false);
      return;
    }

    toast.success("Questionnaire question updated successfully.");
    onSuccess();
    setOpen(false);
    setisLoading(false);
  };

  const fieldSelectHandler = (index) => {
    setTypefield((prevField) =>
      prevField.map((e, i) => {
        if (i == index) {
          e.status = true;
        } else {
          e.status = false;
        }
        return e;
      })
    );
    let updatedField = field;
    const selectedQuestionType = typefield.filter(
      (e, i) => e.status == true
    )[0];
    updatedField.map((e, i) => {
      e.type = selectedQuestionType;
    });
    setField(updatedField);
  };

  useEffect(() => {
    const selectedQuestionType = typefield.filter(
      (e, i) => e.status == true
    )[0];
    let updatedField = field;
    if (selectedQuestionType?.type) {
      setQuestionType(selectedQuestionType?.type);
      setField(
        updatedField.map((e, i) => {
          e.type = selectedQuestionType?.type;
          return e;
        })
      );
    }
  }, [typefield]);

  const addFieldHanler = () => {
    setField((prevField) => [
      ...prevField,
      {
        type: typefield.filter((e, i) => e.status == true)[0].type,
        id: field.length,
        value: "",
      },
    ]);
  };

  const removeFieldHandler = (index) => {
    const prevField = field;
    let fieldToSet = prevField
      .filter((e, i) => i !== index)
      .map((e, i) => {
        e.id = i;
        return e;
      });
    setField(fieldToSet);
  };

  useEffect(() => {
    console.log(field, "asbfa");
  }, [field]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addQuestionHandler = async (values) => {
    if (questionType === "CHECKBOX") {
      if (field.length < 2) {
        toast.error("At least two options are needed");
        setisLoading(false);
        return;
      }

      if (
        field.some((e) => e.value.trim() === "" || e.valueHindi.trim() === "")
      ) {
        toast.error("Options cannot be empty in either language");
        setisLoading(false);
        return;
      }
    }

    const payloadToSend = {
      questionnaireId: id,
      question_en: values?.question?.trim(),
      question_hi: values?.questionHindi?.trim(),
      answerType: field[0].type,
      answerOptions_en: field
        .filter((e) => e.value.trim() !== "")
        .map((e) => e.value.trim()),
      answerOptions_hi: field
        .filter((e) => e.valueHindi.trim() !== "")
        .map((e) => e.valueHindi.trim()),
    };

    console.log(payloadToSend, "payloadToSend");

    const res = await postAPIHandler({
      endPoint: "addQuestionnaireQuestion",
      dataToSend: payloadToSend,
    });

    if (!res) {
      setisLoading(false);
      return;
    }

    if (res?.data?.responseCode === 409) {
      toast.error(res?.data?.responseMessage);
    } else if (res?.data?.responseCode === 200) {
      toast.success("Questionnaire question added successfully.");
      onSuccess();
      setOpen(false);
    } else {
      toast.error("Something went wrong. Please try again.");
    }

    setisLoading(false);
  };

  const style={ textfield:{
    "& .MuiInputBase-root": {
      padding: "12px 20px",
      borderRadius: "8px",
     
      backgroundColor: "#fff",
    },
    "& .MuiInputBase-root:hover": {
      backgroundColor: "#f4f4f4",
      borderColor: "#bbb",
    },
    "& .Mui-focused": {
      borderColor: "rgb(60,115,224)",
    },
    "& input": {
      padding: 0, // Adjust if necessary
    },
  }}

  return (
    <Fragment>
      <Modal
        open={open}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mx: "4px",
        }}
      >
        <Box
          sx={{
            bgcolor: "#fff",
            maxWidth: 640,
            p: 2,
            borderRadius: "22px",
          }}
        >
          <Formik
            validationSchema={ValidationSchema}
            initialValues={{
              question: data?.question_en ? data?.question_en : "",
              questionHindi: data?.question_hi ? data?.question_hi : "",
            }}
            onSubmit={(values) => {
              if (type == AssessmentType.ADD) {
                addQuestionHandler(values);
              } else {
                updateQuestionHandler(values);
              }
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Box
                  sx={{
                    bgcolor: "#fff",
                    minWidth: { xs: "100%", sm: 600 },
                    p: { xs: 0, md: 3 },
                  }}
                >
                  <Typography variant="h5" mb={2} mt={2}>
                    {type === AssessmentType.ADD && "ADD QUESTION"}
                    {type === AssessmentType.EDIT && "EDIT QUESTION"}
                    {type === AssessmentType.VIEW && "VIEW QUESTION"}
                  </Typography>

                  <Grid
                    container
                    item
                    xs={12}
                    alignItems={"center"}
                    sx={{
                      gap: { sm: 1, lg: 2 },
                      justifyContent: {
                        sm: "flex-start",
                      },
                    }}
                    mt={2}
                  >
                    <Grid container item xs={12} justifyContent={"center"}>
                      {AssessmentType.VIEW !== type ? (
                        <TextField
                          inputProps={{
                            maxLength: 255,
                          }}
                          
                          name="question"
                          fullWidth
                          placeholder="Enter question"
                          value={values.question}
                          variant="outlined"
                          sx={style.textfield}
                         
                         
                          disabled={AssessmentType.VIEW === type}
                          // onChange={handleChange}

                          onChange={(e) => {
                            const englishText = e.target.value;
                            handleChange(e);

                            // Transliterate using Google API
                            transliterateToHindi(englishText).then(
                              (hindiText) => {
                                setFieldValue("questionHindi", hindiText);
                              }
                            );
                          }}
                          onBlur={handleBlur}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          error={touched.question && Boolean(errors.question)}
                          helperText={touched.question && errors.question}
                        />
                      ) : (
                        <>
                          <Grid container item xs={12} gap={1}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: AssessmentType.VIEW
                                    ? "bold"
                                    : "normal",
                                }}
                              >
                                Title:
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                {data?.question_en}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid container item xs={12} justifyContent={"center"}>
                      {AssessmentType.VIEW !== type ? (
                        <TextField
                          inputProps={{
                            maxLength: 255,
                          }}
                          name="questionHindi"
                          fullWidth
                          placeholder="हिंदी में प्रश्न दर्ज करें"
                          value={values.questionHindi}
                          variant="outlined"
                          sx={style.textfield}
                          disabled={AssessmentType.VIEW === type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          error={
                            touched.questionHindi &&
                            Boolean(errors.questionHindi)
                          }
                          helperText={
                            touched.questionHindi && errors.questionHindi
                          }
                        />
                      ) : (
                        <>
                          <Grid container item xs={12} gap={1}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: AssessmentType.VIEW
                                    ? "bold"
                                    : "normal",
                                }}
                              >
                                शीर्षक:
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                {data?.question_hi}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid
                      container
                      item
                      sm={12}
                      lg={12}
                      // sx={{
                      //   mt: { xs: 4, md: 1 },
                      // }}
                    >
                      <label
                        style={{
                          whiteSpace: "pre-line" ,
                          fontWeight: AssessmentType.VIEW ? "bold" : "normal",
                        }}
                      >
                        Answer Type :
                      </label>
                    </Grid>

                    <Grid container item sm={12} lg={12} gap={1}>
                      {AssessmentType.VIEW === type &&
                        (typefield
                          .filter((e, i) => e?.status === true)[0]
                          .type?.toUpperCase() === "TEXTBAR".toUpperCase()
                          ? "TEXTFIELD".charAt(0).toUpperCase() +
                            "TEXTFIELD".slice(1).toLowerCase()
                          : typefield
                              .filter((e, i) => e?.status === true)[0]
                              .type?.charAt(0)
                              .toUpperCase() +
                            typefield
                              .filter((e, i) => e?.status === true)[0]
                              .type?.slice(1)
                              .toLowerCase())}

                      {AssessmentType.VIEW != type &&
                        typefield.map((e, i) => {
                          return (
                            <IconButton
                              sx={{ p: 0 }}
                              onClick={() => {
                                fieldSelectHandler(i);
                              }}
                              disabled={AssessmentType.VIEW == type}
                            >
                              <Chip
                                label={
                                  <Typography variant="body2" fontSize={12}>
                                    {e.type.toUpperCase() ==
                                    "textBar".toLocaleUpperCase()
                                      ? "TextField".toUpperCase()
                                      : e.type.toUpperCase()}
                                  </Typography>
                                }
                                size="medium"
                                variant="filled"
                                sx={{
                                  cursor: "grab",
                                  p: "0px",
                                  background:
                                    e.status == true ? "green" : "#ebebeb",
                                  color: e.status == true ? "white" : "#111111",
                                }}
                              />
                            </IconButton>
                          );
                        })}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    lg={12}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mt={2}
                  >
                    {typefield.filter((e, i) => e.type == "TEXTAREA")[0]
                      .status == true ||
                    typefield.filter((e, i) => e.type == "TEXTFIELD")[0]
                      .status == true ? (
                      <></>
                    ) : (
                      <>
                        {AssessmentType.VIEW != type ? (
                          <Typography variant="body1">Add Option: </Typography>
                        ) : (
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: AssessmentType.VIEW
                                ? "bold"
                                : "normal",
                            }}
                          >
                            Options:{" "}
                          </Typography>
                        )}

                        {field.length < 1 ? (
                          AssessmentType.VIEW != type ? (
                            <IconButton
                              size="large"
                              onClick={() => {
                                addFieldHanler();
                              }}
                            >
                              <AddCircleIcon
                                fontSize="large"
                                sx={{ color: "#6a2263" }}
                              />
                            </IconButton>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Grid>

                  <Grid
                    container
                    item
                    sm={12}
                    lg={12}
                    gap={1}
                    mt={4}
                    maxHeight={200}
                    overflow={"auto"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    className="removeScrollbar"
                  >
                    {AssessmentType.VIEW !== type ? (
                      typefield.filter(
                        (e, i) => e.type == "TEXTAREA" || e.type == "TEXTFIELD"
                      )[0].status == true ||
                      typefield.filter((e, i) => e.type == "TEXTFIELD")[0]
                        .status == true ? (
                        <></>
                      ) : (
                        field.map((e, i) => {
                          return (
                            <Grid container item xs={12} gap={2}>
                              {/* English Option Field */}
                              <Grid item xs={12}>
                                <TextField
                                  placeholder="Enter option in English"
                                  inputProps={{
                                    maxLength: 255,
                                  }}
                                  sx={style.textfield}
                                  disabled={AssessmentType.VIEW === type}
                                  value={e?.value}
                                  onChange={(event) => {
                                    const englishText =
                                      event.currentTarget.value;

                                    // Update English field
                                    let newField = field.map((data, index) => {
                                      if (data.id === i) {
                                        data.value = englishText;
                                      }
                                      return data;
                                    });

                                    setField(newField);

                                    // Transliterate and update Hindi field
                                    transliterateToHindi(englishText).then(
                                      (hindiText) => {
                                        let updatedField = newField.map(
                                          (data, index) => {
                                            if (data.id === i) {
                                              data.valueHindi = hindiText;
                                            }
                                            return data;
                                          }
                                        );
                                        setField(updatedField);
                                      }
                                    );
                                  }}
                                  fullWidth
                                  type="text"
                                  InputProps={{
                                  
                                    endAdornment:
                                      i === field.length - 1 ? (
                                        <InputAdornment position="end">
                                          {AssessmentType.VIEW !== type ? (
                                            <IconButton
                                              size="large"
                                              onClick={() => {
                                                addFieldHanler();
                                              }}
                                            >
                                              <AddCircleIcon
                                                fontSize="medium"
                                                sx={{
                                                  color: "#5D9FED",
                                                  position: "relative",
                                                  left: "4px",
                                                }}
                                              />
                                            </IconButton>
                                          ) : (
                                            <></>
                                          )}
                                        </InputAdornment>
                                      ) : (
                                        <InputAdornment position="end">
                                          {AssessmentType.VIEW !== type ? (
                                            <IconButton
                                              onClick={() => {
                                                removeFieldHandler(i);
                                              }}
                                            >
                                              <CloseIcon
                                                sx={{ color: "red" }}
                                              />
                                            </IconButton>
                                          ) : (
                                            <></>
                                          )}
                                        </InputAdornment>
                                      ),
                                  }}
                                />
                              </Grid>

                              {/* Hindi Option Field */}
                              <Grid item xs={12}>
                                <TextField
                                  placeholder="हिंदी में विकल्प दर्ज करें"
                                  inputProps={{
                                    maxLength: 255,
                                  }}
                                  disabled={AssessmentType.VIEW === type}
                                  value={e?.valueHindi}
                                  onChange={(event) => {
                                    const hindiText = event.currentTarget.value;

                                    let newField = field.map((data, index) => {
                                      if (data.id === i) {
                                        data.valueHindi = hindiText;
                                      }
                                      return data;
                                    });

                                    setField(newField);
                                  }}
                                  fullWidth
                                  type="text"
                                  sx={style.textfield}
                                />
                              </Grid>
                            </Grid>
                          );
                        })
                      )
                    ) : (
                      <>
                        {field.map((e, i) => {
                          return (
                            <Grid container item xs={12} gap={1}>
                              <Grid item xs={1}>
                                <Typography variant="body2">
                                  {i + 1}.
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  variant="body2"
                                  whiteSpace={"normal"}
                                >
                                  {e.value}
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  variant="body2"
                                  whiteSpace={"normal"}
                                >
                                  {e.valueHindi}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </>
                    )}
                  </Grid>

                  <Grid
                    xs={12}
                    container
                    item
                    justifyContent={"center"}
                    gap={4}
                    sx={{
                      mt: 4,
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "gray",
                      }}
                      onClick={() => {
                        if (AssessmentType.EDIT == type) {
                          editQuestionLoader();
                        }
                        setOpen(false);
                      }}
                      disabled={isLoading}
                      sx={{
                        px: 6,
                      }}
                    >
                      Back
                    </Button>
                    {AssessmentType.VIEW != type ? (
                      <LoadingButton
                        disabled={isLoading}
                        loading={isLoading}
                        variant="contained"
                        style={{
                          backgroundColor: "#5D9FED",
                        }}
                        type="submit"
                        sx={{
                          px: 6,
                        }}
                      >
                        {AssessmentType.EDIT == type ? "UPDATE" : "ADD"}
                      </LoadingButton>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default AddEditQuestion;
