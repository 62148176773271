import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Typography,
  Grid,
  Button,
  IconButton,
  TextField,
  Radio,
  FormHelperText,
  RadioGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addBroadcastSchema } from "../../../schema/index";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import FormControlLabel from "@mui/material/FormControlLabel";
import { postAPIHandler } from "../../../config/service";
import { transliterateToHindi } from "../../../config/service";

const AddBroadcast = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    console.log(values, "values");
    const payloadToSend = {
      broadcastFor: values.broadcastFor,
      title_en: values.broadcastTitle,
      title_hi: values.broadcastTitleHindi,
      message_en: values.broadcastMessage,
      message_hi: values.broadcastMessageHindi,
    };

    try {
      const response = await postAPIHandler({
        endPoint: "sendBroadcastMessage",
        dataToSend: payloadToSend,
      });
      console.log(response, "response");

      if (response && response.data.responseCode === 200) {
        navigate("/broadcast");
      } else {
        console.error("broadcast already exists.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const style = {
    textfield: {
      "& .MuiInputBase-root": {
        padding: "12px 20px",
        borderRadius: "8px",
       
        backgroundColor: "#fff",
      },
      "& .MuiInputBase-root:hover": {
        backgroundColor: "#f4f4f4",
        borderColor: "#bbb",
      },
      "& .Mui-focused": {
        borderColor: "rgb(60,115,224)",
      },
      "& input": {
        padding: 0, // Adjust if necessary
      },
    },

    select:{
      "& .MuiInputBase-root": {
        padding: "12px 20px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
      },
      "& .MuiInputBase-root:hover": {
        backgroundColor: "#f4f4f4",
        borderColor: "#bbb",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgb(60,115,224)",
      },
      "& .MuiSelect-select": {
        padding: "12px 20px", // Matches the TextField padding
      },
      "& .Mui-disabled": {
        backgroundColor: "#f5f5f5",
        color: "#999",
      },
    }
  };

  return (
    <Grid container justifyContent={"center"}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0px 8%",
        }}
        item
        xs={12}
        mb={2}
      >
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: "#2A73E0",
          }}
        >
          <ArrowCircleLeftIcon
            sx={{
              fontSize: "30px",
            }}
          />
        </IconButton>
        <Typography color={"#262626"} fontWeight={"600"} variant="h5">
          Add Broadcast (प्रसारण जोड़ें)
        </Typography>
      </Grid>

      <Grid item container xs={12}>
        <Formik
          initialValues={{
            broadcastFor: "",
            broadcastTitle: "",
            broadcastTitleHindi: "",
            broadcastMessage: "",
            broadcastMessageHindi: "",
          }}
          validationSchema={addBroadcastSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form
              style={{
                minWidth: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                item
                md={10}
                xs={12}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  p: "10px",
                }}
              >
                <Grid container item xs={12} alignItems={"center"} mt={2}>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems={"center"}
                    sx={{
                      gap: { sm: 1, lg: 6 },
                      justifyContent: { sm: "flex-start", md: "center" },
                    }}
                    mt={2}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems={"center"}
                      sx={{
                        gap: { sm: 1, lg: 6 },
                        justifyContent: { sm: "flex-start", md: "center" },
                      }}
                      mt={2}
                    >
                      <Grid container item sm={12} lg={2}>
                        <label
                          style={{
                            whiteSpace: "pre-line" ,
                          }}
                        >
                          Select User (उपयोगकर्ता चुनें):
                        </label>
                      </Grid>

                      <Grid container item sm={12} lg={4}>
                        <RadioGroup row name="broadcastFor">
                          <FormControlLabel
                            control={
                              <Field
                                as={Radio}
                                type="radio"
                                name="broadcastFor"
                                value="ALL"
                              />
                            }
                            label="All"
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                color: "black",
                                fontWeight: 500,
                                fontSize: "16px",
                              },
                            }}
                          />
                          <FormControlLabel
                            control={
                              <Field
                                as={Radio}
                                type="radio"
                                name="broadcastFor"
                                value="CAREGIVER"
                              />
                            }
                            label="Caregiver"
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                color: "black",
                                fontWeight: 500,
                                fontSize: "16px",
                              },
                            }}
                          />
                          <FormControlLabel
                            control={
                              <Field
                                as={Radio}
                                type="radio"
                                name="broadcastFor"
                                value="ASHA_WORKER"
                              />
                            }
                            label="Asha Worker"
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                color: "black",
                                fontWeight: 500,
                                fontSize: "16px",
                              },
                            }}
                          />
                        </RadioGroup>

                        {errors.broadcastFor && touched.broadcastFor && (
                          <FormHelperText error>
                            {errors.broadcastFor}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  alignItems={"center"}
                  sx={{
                    gap: { sm: 1, lg: 6 },
                    justifyContent: { sm: "flex-start", md: "center" },
                  }}
                  mt={2}
                >
                  {/* Broadcast Title */}
                  <Grid container item sm={12} lg={2}>
                    <label style={{ whiteSpace: "pre-line"  }}>
                      Title (शीर्षक):
                    </label>
                  </Grid>

                  <Grid container item sm={12} lg={4}>
                    <TextField
                    fullWidth
                      type="text"
                      id="broadcastTitle"
                      name="broadcastTitle"
                      placeholder="Enter broadcast title"
                      inputProps={{
                        maxLength: 100,
                       
                      }}

                      sx={style.textfield}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      disabled={isLoading}
                      value={values.broadcastTitle}
                      // onChange={(e) => {
                      //   const englishText = e.target.value;
                      //   const hindiText = transliterateToHindi(englishText);
                      //   handleChange(e);
                      //   setFieldValue("broadcastTitleHindi", hindiText);
                      // }}

                      onChange={(e) => {
                        const englishText = e.target.value;
                        handleChange(e);
                        transliterateToHindi(englishText).then((hindiText) => {
                          setFieldValue("broadcastTitleHindi", hindiText);
                        });
                      }}
                      onBlur={handleBlur}
                      error={
                        touched.broadcastTitle && Boolean(errors.broadcastTitle)
                      }
                      helperText={
                        touched.broadcastTitle && errors.broadcastTitle
                      }
                     
                    />
                  </Grid>

                  {/* Broadcast Title Hindi */}
                  <Grid container item sm={12} lg={4}>
                    <TextField
                    fullWidth
                      type="text"
                      id="broadcastTitleHindi"
                      name="broadcastTitleHindi"
                      placeholder="प्रसारण शीर्षक दर्ज करें"
                      inputProps={{
                        maxLength: 100,
                       
                      }}

                      sx={style.textfield}
                      FormHelperTextProps={{
                      
                      }}
                      disabled={isLoading}
                      value={values.broadcastTitleHindi}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.broadcastTitleHindi &&
                        Boolean(errors.broadcastTitleHindi)
                      }
                      helperText={
                        touched.broadcastTitleHindi &&
                        errors.broadcastTitleHindi
                      }
                      
                    />
                  </Grid>
                </Grid>

                {/* Broadcast Message */}
                <Grid
  container
  item
  xs={12}
  alignItems={"center"}
  sx={{
    gap: { sm: 1, lg: 6 },
    justifyContent: { sm: "flex-start", md: "center" },
  }}
  mt={2}
>
  {/* Broadcast Message */}
  <Grid container item sm={12} lg={2}>
    <label style={{ whiteSpace: "pre-line"  }}>
      Message (संदेश):
    </label>
  </Grid>

  <Grid container item sm={12} lg={4}>
    <TextField
    fullWidth
      type="text"
      id="broadcastMessage"
      name="broadcastMessage"
      placeholder="Enter broadcast message"
      multiline
      rows={5} // Adjust rows for desired height
      inputProps={{
        maxLength: 1200,
      }}
      sx={style.textfield}
      FormHelperTextProps={{
        style: {
          marginLeft: "0px",
        },
      }}
      disabled={isLoading}
      value={values.broadcastMessage}
      onChange={(e) => {
        const englishText = e.target.value;
        handleChange(e);
        transliterateToHindi(englishText).then((hindiText) => {
          setFieldValue("broadcastMessageHindi", hindiText);
        });
      }}
      onBlur={handleBlur}
      error={
        touched.broadcastMessage &&
        Boolean(errors.broadcastMessage)
      }
      helperText={
        touched.broadcastMessage && errors.broadcastMessage
      }
    />
  </Grid>

  {/* Broadcast Message Hindi */}
  <Grid container item sm={12} lg={4}>
    <TextField
    fullWidth
      type="text"
      id="broadcastMessageHindi"
      name="broadcastMessageHindi"
      placeholder="प्रसारण संदेश दर्ज करें"
      multiline
      rows={5} // Adjust rows for desired height
      inputProps={{
        maxLength: 1200,
      }}
      sx={style.textfield}
      FormHelperTextProps={{
        style: {
          marginLeft: "0px",
        },
      }}
      disabled={isLoading}
      value={values.broadcastMessageHindi}
      onChange={handleChange}
      onBlur={handleBlur}
      error={
        touched.broadcastMessageHindi &&
        Boolean(errors.broadcastMessageHindi)
      }
      helperText={
        touched.broadcastMessageHindi &&
        errors.broadcastMessageHindi
      }
    />
  </Grid>
</Grid>


                {/* Action Buttons */}
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={"center"}
                  mt={6}
                  mb={8}
                  sx={{ gap: { xs: 4, md: 10 } }}
                >
                  <Grid item container xs={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ background: "gray" }}
                      onClick={() => {
                        navigate(-1);
                      }}
                      disabled={isLoading}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item container xs={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ background: "#F83629" }}
                      type="submit"
                      loading={isLoading}
                    >
                      ADD
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default AddBroadcast;
