import {
  Grid,
  Button,
  Typography,
  Divider,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  Pagination,
  IconButton,
  Box,
  Tooltip,
  Avatar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
// import shortUid from "short-uuid";
import React, { useEffect, useId, useRef, useState } from "react";
import Filter from "../../../component/Filter";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
// import { useScrollContainer } from "react-indiana-drag-scroll";
import NoDataFound from "../../../component/NoDataFound";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import { CSVDownload, CSVLink } from "react-csv";
import toast from "react-hot-toast";
import { Delete, LensTwoTone } from "@mui/icons-material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ReassignCareGiverPopUp from "../../../component/ReassignCareGiverPopUp";
import { getAPIHandler, putAPIHandler } from "../../../config/service";
import ReassignPopUp from "../../../component/ReaasignPopUP";
import { ApiConfig } from "../../../config/ApiConfig";
import axios from "axios";
const tableHeadData = [
  "S.No",
  "Caregiver ID",
  "Caregiver  Name",
  "Date & Time",
  "State",
  "District",
  "City",
  "Action",
];

const ViewASHAWorker = () => {
  const [open, setOpen] = useState(false);
  const [listData, setListData] = useState([]);
  console.log("listData: ", listData);
  const [isDataLoading, setisDataLoading] = useState(true);
  const [isDataUpdate, setIsDataUpdate] = useState(false);
  const navigate = useNavigate();
  const [selectedCareGiverId, setSelectedCareGiverId] = useState("");
  const [updateList, setUpdateList] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvRef = useRef();
  const [isCsvDataLoading, setIsCsvDataLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [reassignPopUp, setReassignPopUp] = useState(false);
  const [assignPopUp, setAssignPopUp] = useState(false);
  const [allAshaWorker, setAllAshaWorker] = useState([]);
  const { state } = useLocation();
  const [ashaWorkerId, setAshaWorkerId] = useState(state?.value?.id);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    name: "",
    fromDate: "",
    toDate: "",
  });

  const ashaWorkerDetails = [
    { label: "Full Name", value: state?.value?.fullName_en || "N/A" },
    { label: "Email", value: state?.value?.email_en || "N/A" },
    {
      label: "Phone Number",
      value:
        `${state?.value?.countryCode_en} ${state?.value?.mobileNumber_en}` ||
        "N/A",
    },
    { label: "Address", value: state?.value?.address_en || "N/A" },
  ];

  const renderDetails = (title, details) => (
    <Grid
      container
      item
      xs={12}
      sx={{
        p: { lg: 4, sm: 2, xs: 1 },
        boxShadow: { lg: "rgba(0, 0, 0, 0.16) 0px 1px 4px", xs: "none" },
        borderRadius: 6,
        mb: 3,
        mt: 2,
        bgcolor: "background.paper",
        display: "flex",
        justifyContent: "center",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "scale(1.01)",
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 20px",
        },
      }}
    >
      <Grid
        item
        container
        xs={12}
        lg={3}
        justifyContent={{ lg: "center", xs: "center" }}
        alignItems="center"
        sx={{ mb: { xs: 2, lg: 0 } }}
      >
        <Avatar
          sx={{
            height: "160px",
            width: "160px",
            transition: "transform 0.3s ease",
            "&:hover": { transform: "scale(1.1)" },
          }}
          src={state?.value?.profileUrl} // Replace with actual avatar image URL
        />
      </Grid>

      <Grid
        item
        container
        sm={12}
        lg={6}
        justifyContent={"center"}
        sx={{ mt: { lg: 0, xs: 6 } }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              color: "#2A73E0",
              fontWeight: "bold",
              textTransform: "uppercase",
              letterSpacing: 1,
            }}
          >
            {title}
          </Typography>
          {details.map((detail, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 1,
                // "&:hover": {
                //   backgroundColor: "#f7f7f7",
                //   borderRadius: "4px",
                //   padding: "4px",
                // },
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", mr: 1, color: "#3f3f3f" }}
              >
                {detail.label}:
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#666", fontWeight: "400" }}
              >
                {detail.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );

  async function listAshaWorkerAssignedCaregivers(params) {
    const filterToSend = filter;
    const paramsToSend = {
      ashaWorkerId: ashaWorkerId,
      ...Object.fromEntries(
        Object.entries(filterToSend).filter(([_, v]) => v != "")
      ),
    };
    setisDataLoading(true);
    const response = await getAPIHandler({
      endPoint: "listAshaWorkerAssignedCaregivers",
      paramsData: paramsToSend,
    });
    console.log(response, "response");
    if (!response || response.status !== 200) {
      setisDataLoading(false);
      return;
    }
    const categoryList = response?.data?.result?.caregiversList || [];
    const totalPages = response?.data?.result?.totalPages || 1;

    setListData([]);
    setListData(categoryList);
    setTotalPages(totalPages);

    setisDataLoading(false);
  }

  const listAllAshaWorker = async (state) => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.listAllUsers,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        params: {
          userType: "ASHA_WORKER",
          state: state,
        },
      });

      if (res?.data?.responseCode === 200) {
        console.log(res?.data?.result?.userList, "ghjghjhj");
        setAllAshaWorker(res?.data?.result?.userList);
        setUpdateList(false);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };
  async function csvDownloder() {
    // setIsCsvDataLoading(true);

    let dataToBeDownload = [];
    const tableHeadDataToCsv = [
      "S.No",
      "Caregiver ID",
      "Caregiver  Name",
      "Date & Time",
      "State",
      "District",
      "City",
    ];

    dataToBeDownload.push(tableHeadDataToCsv);
    dataToBeDownload = [
      ...dataToBeDownload,
      ...listData?.map((e, i) => [
        i + 1,

        e?.uniqueId ? e?.uniqueId : "-",
        e?.fullName ? e?.fullName : "-",
        e?.questionsCount ? e?.questionsCount : "-",
        e?.createdAt ? dayjs(e?.createdAt).format("MMM D YYYY h:mm A") : "-",
        e?.state ? e?.state : "-",
        e?.district ? e?.district : "-",
        e?.city ? e?.city : "-",
      ]),
    ];

    const csvBlob = new Blob(
      [dataToBeDownload.map((row) => row.join(",")).join("\n")],
      { type: "text/csv" }
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(csvBlob);
    downloadLink.download = "assigned_caregiver_data.csv";

    setTimeout(() => {
      setIsCsvDataLoading(false);
      downloadLink.click();
    }, 1000);
  }

  useEffect(() => {
    listAshaWorkerAssignedCaregivers();
  }, [isDataUpdate, filter, updateList]);

  return (
    <Grid container>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
          //   padding: "0px 8%",
        }}
        item
        xs={12}
        mb={2}
      >
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: "#2A73E0",
          }}
        >
          <ArrowCircleLeftIcon
            sx={{
              fontSize: "30px",
            }}
          />
        </IconButton>
        <Typography color={"#262626"} fontWeight={"600"} variant="h5">
          View ASHA Worker
        </Typography>
      </Grid>
      <Grid container>
        {renderDetails("ASHA Worker Details", ashaWorkerDetails, true)}
      </Grid>
      <Grid
        container
        item
        style={{ backgroundColor: "#ffffff" }}
        sx={{ mt: { xs: 6, md: 2, lg: 0 } }}
        alignItems="center"
      >
        <Grid item mb={4} container justifyContent={"space-between"}>
          <Typography color={"#262626"} fontWeight={"600"} variant="h5">
            Assigned Caregiver Listing
          </Typography>

          <Grid
            container
            item
            xs={6}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={4}
          >
            <Button
              variant="outlined"
              sx={{
                color: "#2A73E0",
                border: "1px solid #2A73E0",
                px: { xs: 3 },
                py: { xs: 1.3 },
                mb: { xs: 2, md: 0 },
              }}
              onClick={() => {
                setAshaWorkerId(state?.value?.id);
                setAssignPopUp(true);
              }}
            >
              <AddIcon />
              Assign New Caregiver
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} alignItems={"center"}>
          <Divider sx={{ background: "#DFDFDF" }} />
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          mt={2}
          // mr={2}
        >
          <Tooltip title="Download CSV">
            <Button
              variant="contained"
              style={{ backgroundColor: "#1D60B3" }}
              sx={{ px: 4, py: 1.5, borderRadius: "24px" }}
              onClick={() => {
                csvDownloder();
              }}
              loading={isCsvDataLoading}
            >
              Download CSV
            </Button>
          </Tooltip>
        </Grid>

        <Filter setFilter={setFilter} />
        {listData?.length == 0 ? (
          <NoDataFound message={"No Assigned Caregiver Found"} />
        ) : (
          <TableContainer
            className="removeScrollbar"
            // ref={scrollContainer.ref}
            component={Paper}
            sx={{
              mt: 4,
              overflowX: "scroll",
              width: "100%",
              // minHeight: "520px",
            }}
          >
            <Table sx={{ minWidth: 1200 }}>
              <TableHead
                sx={{
                  bgcolor: "#2A73E0",
                }}
              >
                <TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      padding: "4px",
                      height: "45px",
                    },
                  }}
                >
                  {tableHeadData.map((e, i) => (
                    <TableCell key={i} align="center" sx={{ color: "white" }}>
                      {e}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listData?.map((data, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      "& .MuiTableCell-root": {
                        padding: "4px",
                      },
                    }}
                  >
                    <TableCell align="center" scope="row">
                      {currentPage ? (currentPage - 1) * 10 + (i + 1) : i + 1}
                    </TableCell>

                    <Tooltip title={data?.uniqueId ? data?.uniqueId : "-"}>
                      <TableCell align="center" scope="row">
                        {data?.uniqueId
                          ? data?.uniqueId?.length > 12
                            ? data?.uniqueId?.slice(0, 12) + "..."
                            : data?.uniqueId
                          : "-"}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={data?.fullName ? data?.fullName : "-"}>
                      <TableCell align="center" scope="row">
                        {data?.fullName
                          ? data?.fullName.length > 20
                            ? data?.fullName.slice(0, 20) + "..."
                            : data?.fullName
                          : "-"}
                      </TableCell>
                    </Tooltip>

                    <TableCell align="center" scope="row">
                      {dayjs(data?.createdAt).format("MMM D YYYY h:mm A")}
                    </TableCell>

                    <Tooltip title={data?.state ? data?.state : "-"}>
                      <TableCell align="center" scope="row">
                        {data?.state
                          ? data?.state.length > 16
                            ? data?.state.slice(0, 16) + "..."
                            : data?.state
                          : "-"}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={data?.district ? data?.district : "-"}>
                      <TableCell align="center" scope="row">
                        {data?.district
                          ? data?.district.length > 16
                            ? data?.district.slice(0, 16) + "..."
                            : data?.district
                          : "-"}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={data?.city ? data?.city : "-"}>
                      <TableCell align="center" scope="row">
                        {data?.city
                          ? data?.city.length > 16
                            ? data?.city.slice(0, 16) + "..."
                            : data?.city
                          : "-"}
                      </TableCell>
                    </Tooltip>


                    <TableCell align="center" scope="row">
                      <Tooltip title={"Reassign advisor"}>
                        <Button
                          variant="text"
                          onClick={() => {
                            setSelectedCareGiverId(data?.id);
                            listAllAshaWorker(data?.state);
                            setReassignPopUp(true);
                          }}
                          style={{
                            background: "#2A73E0",
                            textTransform: "none",
                            color: "white",
                          }}
                        >
                          Reassign
                        </Button>
                      </Tooltip>

                      {/* <Tooltip title={"Reassign advisor"}>
                        <Button
                          variant="text"

                          onClick={() => {
                            setSelectedCareGiverId(data?.id)
                            listAllAshaWorker()
                            setReassignPopUp(true);
                          }}
                          style={{
                            background: "#2A73E0",
                            textTransform: "none",
                            color: "white",
                            marginLeft:"5px"
                          }}
                        >
                         Remove
                        </Button>
                      </Tooltip> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Grid item container justifyContent={"flex-end"} mt={4}>
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            defaultPage={filter.page}
            onChange={(e, page) => {
              const updatedFilter = { ...filter, page: page };
              setCurrentPage(page);
              setFilter(updatedFilter);
            }}
            sx={{ color: "#681e65" }}
          />
        )}
      </Grid>
      <ReassignCareGiverPopUp
        open={assignPopUp}
        setOpen={setAssignPopUp}
        ashaWorkerId={ashaWorkerId}
        setUpdateList={setUpdateList}
      />
      <ReassignPopUp
        open={reassignPopUp}
        setOpen={setReassignPopUp}
        allAshaWorker={allAshaWorker}
        selectedCareGiverId={selectedCareGiverId}
        setUpdateList={setUpdateList}
      />{" "}
    </Grid>
  );
};

export default ViewASHAWorker;
