export const CareGiverData = [
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
];

export const categoryData = {
  categoryName: "Medication",
  categoryNameHindi: "दवा",
  thumbnailImage: "https://example.com/path/to/image.jpg",
};

export const statesData = [
  {
    state: "Andhra Pradesh",
    stateHindi: "आंध्र प्रदेश",
    cities: [
      { city: "Visakhapatnam", cityHindi: "विशाखापटणम्", districts: [{ district: "Visakhapatnam", districtHindi: "विशाखापटणम्" }] },
      { city: "Vijayawada", cityHindi: "विजयवादा", districts: [{ district: "Krishna", districtHindi: "कृष्णा" }] },
      { city: "Guntur", cityHindi: "गुंटूर", districts: [{ district: "Guntur", districtHindi: "गुंटूर" }] },
      { city: "Nellore", cityHindi: "नेल्लोर", districts: [{ district: "Nellore", districtHindi: "नेल्लोर" }] },
      { city: "Kurnool", cityHindi: "कुर्नूल", districts: [{ district: "Kurnool", districtHindi: "कुर्नूल" }] },
      { city: "Tirupati", cityHindi: "तिरुपति", districts: [{ district: "Tirupati", districtHindi: "तिरुपति" }] },
      { city: "Rajahmundry", cityHindi: "राजहमुंद्री", districts: [{ district: "East Godavari", districtHindi: "पूर्वी गोदावरी" }] },
      { city: "Anantapur", cityHindi: "अनंतपुर", districts: [{ district: "Anantapur", districtHindi: "अनंतपुर" }] },
      { city: "Kadapa", cityHindi: "कडप्प", districts: [{ district: "Cuddapah", districtHindi: "कडप्प" }] },
      { city: "Ongole", cityHindi: "ओंगोल", districts: [{ district: "Prakasam", districtHindi: "प्रकाशम" }] },
      { city: "Kakinada", cityHindi: "काकीनाडा", districts: [{ district: "East Godavari", districtHindi: "पूर्वी गोदावरी" }] }
    ]
  },
  {
    state: "Arunachal Pradesh",
    stateHindi: "अरुणाचल प्रदेश",
    cities: [
      { city: "Itanagar", cityHindi: "इटानगर", districts: [{ district: "Papum Pare", districtHindi: "पापुम पेरे" }] },
      { city: "Tawang", cityHindi: "तवांग", districts: [{ district: "Tawang", districtHindi: "तवांग" }] },
      { city: "Ziro", cityHindi: "ज़िरो", districts: [{ district: "Lower Subansiri", districtHindi: "लोअर सुबंसिरी" }] },
      { city: "Bomdila", cityHindi: "बोमडिला", districts: [{ district: "West Kameng", districtHindi: "वेस्ट कामेंग" }] },
      { city: "Pasighat", cityHindi: "पासिघाट", districts: [{ district: "Upper Siang", districtHindi: "अप्पर सियांग" }] }
    ]
  },
  {
    state: "Assam",
    stateHindi: "असम",
    cities: [
      { city: "Guwahati", cityHindi: "गुवाहाटी", districts: [{ district: "Kamrup", districtHindi: "कामरूप" }] },
      { city: "Silchar", cityHindi: "सिलचर", districts: [{ district: "Cachar", districtHindi: "कच्छर" }] },
      { city: "Dibrugarh", cityHindi: "डिब्रूगढ़", districts: [{ district: "Dibrugarh", districtHindi: "डिब्रूगढ़" }] },
      { city: "Jorhat", cityHindi: "जोरहाट", districts: [{ district: "Jorhat", districtHindi: "जोरहाट" }] },
      { city: "Tezpur", cityHindi: "तेजपुर", districts: [{ district: "Sonitpur", districtHindi: "सोनीतपुर" }] },
      { city: "Tinsukia", cityHindi: "तिनसुकिया", districts: [{ district: "Tinsukia", districtHindi: "तिनसुकिया" }] },
      { city: "Sivasagar", cityHindi: "सिवासगर", districts: [{ district: "Sivasagar", districtHindi: "सिवासगर" }] }
    ]
  },
  {
    state: "Bihar",
    stateHindi: "बिहार",
    cities: [
      { city: "Patna", cityHindi: "पटना", districts: [{ district: "Patna", districtHindi: "पटना" }] },
      { city: "Gaya", cityHindi: "गया", districts: [{ district: "Gaya", districtHindi: "गया" }] },
      { city: "Bhagalpur", cityHindi: "भागलपुर", districts: [{ district: "Bhagalpur", districtHindi: "भागलपुर" }] },
      { city: "Muzaffarpur", cityHindi: "मुजफ्फरपुर", districts: [{ district: "Muzaffarpur", districtHindi: "मुजफ्फरपुर" }] },
      { city: "Purnia", cityHindi: "पूरनिया", districts: [{ district: "Purnia", districtHindi: "पूरनिया" }] },
      { city: "Darbhanga", cityHindi: "दरभंगा", districts: [{ district: "Darbhanga", districtHindi: "दरभंगा" }] },
      { city: "Arrah", cityHindi: "अरह", districts: [{ district: "Bhojpur", districtHindi: "भोजपुर" }] },
      { city: "Begusarai", cityHindi: "बेगूसराय", districts: [{ district: "Begusarai", districtHindi: "बेगूसराय" }] }
    ]
  },
  {
    state: "Chhattisgarh",
    stateHindi: "छत्तीसगढ़",
    cities: [
      { city: "Raipur", cityHindi: "रायपुर", districts: [{ district: "Raipur", districtHindi: "रायपुर" }] },
      { city: "Bilaspur", cityHindi: "बिलासपुर", districts: [{ district: "Bilaspur", districtHindi: "बिलासपुर" }] },
      { city: "Durg", cityHindi: "दुर्ग", districts: [{ district: "Durg", districtHindi: "दुर्ग" }] },
      { city: "Bhilai", cityHindi: "भिलाई", districts: [{ district: "Durg", districtHindi: "दुर्ग" }] },
      { city: "Korba", cityHindi: "कोरबा", districts: [{ district: "Korba", districtHindi: "कोरबा" }] },
      { city: "Jagdalpur", cityHindi: "जगदलपुर", districts: [{ district: "Dantewada", districtHindi: "डांटेवाड़ा" }] },
      { city: "Ambikapur", cityHindi: "अंबिकापुर", districts: [{ district: "Surguja", districtHindi: "सुरगुजा" }] }
    ]
  },
  {
    state: "Goa",
    stateHindi: "गोवा",
    cities: [
      { city: "Panaji", cityHindi: "पणजी", districts: [{ district: "North Goa", districtHindi: "नॉर्थ गोवा" }] },
      { city: "Margao", cityHindi: "मारगाओ", districts: [{ district: "South Goa", districtHindi: "साउथ गोवा" }] },
      { city: "Mapusa", cityHindi: "मापुसा", districts: [{ district: "North Goa", districtHindi: "नॉर्थ गोवा" }] },
      { city: "Vasco da Gama", cityHindi: "वास्को दा गामा", districts: [{ district: "South Goa", districtHindi: "साउथ गोवा" }] }
    ]
  },
  {
    state: "Gujarat",
    stateHindi: "गुजरात",
    cities: [
      { city: "Ahmedabad", cityHindi: "अहमदाबाद", districts: [{ district: "Ahmedabad", districtHindi: "अहमदाबाद" }] },
      { city: "Surat", cityHindi: "सूरत", districts: [{ district: "Surat", districtHindi: "सूरत" }] },
      { city: "Vadodara", cityHindi: "वडोदरा", districts: [{ district: "Vadodara", districtHindi: "वडोदरा" }] },
      { city: "Rajkot", cityHindi: "राजकोट", districts: [{ district: "Rajkot", districtHindi: "राजकोट" }] },
      { city: "Bhavnagar", cityHindi: "भावनगर", districts: [{ district: "Bhavnagar", districtHindi: "भावनगर" }] },
      { city: "Jamnagar", cityHindi: "जामनगर", districts: [{ district: "Jamnagar", districtHindi: "जामनगर" }] },
      { city: "Gandhinagar", cityHindi: "गांधीनगर", districts: [{ district: "Gandhinagar", districtHindi: "गांधीनगर" }] },
      { city: "Junagadh", cityHindi: "जूनागढ", districts: [{ district: "Junagadh", districtHindi: "जूनागढ" }] },
      { city: "Anand", cityHindi: "आनंद", districts: [{ district: "Anand", districtHindi: "आनंद" }] },
      { city: "Kheda", cityHindi: "खेडा", districts: [{ district: "Kheda", districtHindi: "खेडा" }] },
      { city: "Mehsana", cityHindi: "मेहसाणा", districts: [{ district: "Mehsana", districtHindi: "मेहसाणा" }] },
      { city: "Nadiad", cityHindi: "नडियाद", districts: [{ district: "Nadiad", districtHindi: "नडियाद" }] }
    ]
  },
  {
    state: "Haryana",
    stateHindi: "हरियाणा",
    cities: [
      { city: "Gurugram", cityHindi: "गुरुग्राम", districts: [{ district: "Gurugram", districtHindi: "गुरुग्राम" }] },
      { city: "Faridabad", cityHindi: "फरीदाबाद", districts: [{ district: "Faridabad", districtHindi: "फरीदाबाद" }] },
      { city: "Panipat", cityHindi: "पानीपत", districts: [{ district: "Panipat", districtHindi: "पानीपत" }] },
      { city: "Hisar", cityHindi: "हिसार", districts: [{ district: "Hisar", districtHindi: "हिसार" }] },
      { city: "Rohtak", cityHindi: "रोहतक", districts: [{ district: "Rohtak", districtHindi: "रोहतक" }] },
      { city: "Ambala", cityHindi: "अम्बाला", districts: [{ district: "Ambala", districtHindi: "अम्बाला" }] },
      { city: "Karnal", cityHindi: "कर्नाल", districts: [{ district: "Karnal", districtHindi: "कर्नाल" }] },
      { city: "Yamunanagar", cityHindi: "यमुनानगर", districts: [{ district: "Yamunanagar", districtHindi: "यमुनानगर" }] },
      { city: "Panchkula", cityHindi: "पंचकुला", districts: [{ district: "Panchkula", districtHindi: "पंचकुला" }] }
    ]
  },
  {
    state: "Himachal Pradesh",
    stateHindi: "हिमाचल प्रदेश",
    cities: [
      { city: "Shimla", cityHindi: "शिमला", districts: [{ district: "Shimla", districtHindi: "शिमला" }] },
      { city: "Manali", cityHindi: "मनाली", districts: [{ district: "Kullu", districtHindi: "कुल्लू" }] },
      { city: "Dharamshala", cityHindi: "धर्मशाला", districts: [{ district: "Kangra", districtHindi: "कांगड़ा" }] },
      { city: "Solan", cityHindi: "सोलन", districts: [{ district: "Solan", districtHindi: "सोलन" }] },
      { city: "Mandi", cityHindi: "मांडी", districts: [{ district: "Mandi", districtHindi: "मांडी" }] },
      { city: "Kullu", cityHindi: "कुल्लू", districts: [{ district: "Kullu", districtHindi: "कुल्लू" }] },
      { city: "Hamirpur", cityHindi: "हमीरपुर", districts: [{ district: "Hamirpur", districtHindi: "हमीरपुर" }] },
      { city: "Una", cityHindi: "उन", districts: [{ district: "Una", districtHindi: "उन" }] },
      { city: "Palampur", cityHindi: "पलमपुर", districts: [{ district: "Kangra", districtHindi: "कांगड़ा" }] }
    ]
  },
  {
    state: "Jammu and Kashmir",
    stateHindi: "जम्मू-कश्मीर",
    cities: [
      { city: "Srinagar", cityHindi: "श्रीनगर", districts: [{ district: "Srinagar", districtHindi: "श्रीनगर" }] },
      { city: "Jammu", cityHindi: "जम्मू", districts: [{ district: "Jammu", districtHindi: "जम्मू" }] },
      { city: "Anantnag", cityHindi: "अनंतनाग", districts: [{ district: "Anantnag", districtHindi: "अनंतनाग" }] },
      { city: "Baramulla", cityHindi: "बारामुल्ला", districts: [{ district: "Baramulla", districtHindi: "बारामुल्ला" }] },
      { city: "Kupwara", cityHindi: "कुपवाड़ा", districts: [{ district: "Kupwara", districtHindi: "कुपवाड़ा" }] },
      { city: "Rajauri", cityHindi: "राजौरी", districts: [{ district: "Rajauri", districtHindi: "राजौरी" }] }
    ]
  },
  {
    state: "Jharkhand",
    stateHindi: "झारखंड",
    cities: [
      { city: "Ranchi", cityHindi: "रांची", districts: [{ district: "Ranchi", districtHindi: "रांची" }] },
      { city: "Jamshedpur", cityHindi: "जमशेदपुर", districts: [{ district: "East Singhbhum", districtHindi: "ईस्ट सिंहभूम" }] },
      { city: "Dhanbad", cityHindi: "धनबाद", districts: [{ district: "Dhanbad", districtHindi: "धनबाद" }] },
      { city: "Bokaro", cityHindi: "बोकारो", districts: [{ district: "Bokaro", districtHindi: "बोकारो" }] },
      { city: "Giridih", cityHindi: "गिरिडीह", districts: [{ district: "Giridih", districtHindi: "गिरिडीह" }] },
      { city: "Deoghar", cityHindi: "देवघर", districts: [{ district: "Deoghar", districtHindi: "देवघर" }] },
      { city: "Ramgarh", cityHindi: "रामगढ़", districts: [{ district: "Ramgarh", districtHindi: "रामगढ़" }] },
      { city: "Hazaribagh", cityHindi: "हजारीबाग", districts: [{ district: "Hazaribagh", districtHindi: "हजारीबाग" }] }
    ]
  },
  {
    state: "Karnataka",
    stateHindi: "कर्नाटक",
    cities: [
      { city: "Bengaluru", cityHindi: "बेंगलुरु", districts: [{ district: "Bengaluru Urban", districtHindi: "बेंगलुरु अर्बन" }] },
      { city: "Mysuru", cityHindi: "मैसूर", districts: [{ district: "Mysuru", districtHindi: "मैसूर" }] },
      { city: "Mangalore", cityHindi: "मैंगलोर", districts: [{ district: "Dakshina Kannada", districtHindi: "दक्षिण कन्नड़" }] },
      { city: "Hubballi-Dharwad", cityHindi: "हब्बळी-धारवाड़", districts: [{ district: "Hubballi-Dharwad", districtHindi: "हब्बळी-धारवाड़" }] },
      { city: "Belgaum", cityHindi: "बेलगाम", districts: [{ district: "Belgaum", districtHindi: "बेलगाम" }] },
      { city: "Gulbarga", cityHindi: "गुलबर्गा", districts: [{ district: "Gulbarga", districtHindi: "गुलबर्गा" }] },
      { city: "Davanagere", cityHindi: "दवणागरे", districts: [{ district: "Davanagere", districtHindi: "दवणागरे" }] },
      { city: "Vijayapura", cityHindi: "विजयपुर", districts: [{ district: "Vijayapura", districtHindi: "विजयपुर" }] },
      { city: "Shimoga", cityHindi: "शिमोगा", districts: [{ district: "Shimoga", districtHindi: "शिमोगा" }] },
      { city: "Tumakuru", cityHindi: "तुमकुरु", districts: [{ district: "Tumakuru", districtHindi: "तुमकुरु" }] },
      { city: "Kalaburagi", cityHindi: "कलबुरागी", districts: [{ district: "Kalaburagi", districtHindi: "कलबुरागी" }] },
      { city: "Bellary", cityHindi: "बेल्लारी", districts: [{ district: "Bellary", districtHindi: "बेल्लारी" }] }
    ]
  },
  {
    state: "Kerala",
    stateHindi: "केरल",
    cities: [
      { city: "Thiruvananthapuram", cityHindi: "तिरुवनंतपुरम", districts: [{ district: "Thiruvananthapuram", districtHindi: "तिरुवनंतपुरम" }] },
      { city: "Kochi", cityHindi: "कोच्चि", districts: [{ district: "Ernakulam", districtHindi: "एर्नाकुलम" }] },
      { city: "Kozhikode", cityHindi: "कोझिकोड", districts: [{ district: "Kozhikode", districtHindi: "कोझिकोड" }] },
      { city: "Kollam", cityHindi: "कोल्लम", districts: [{ district: "Kollam", districtHindi: "कोल्लम" }] },
      { city: "Thrissur", cityHindi: "त्रिशूर", districts: [{ district: "Thrissur", districtHindi: "त्रिशूर" }] },
      { city: "Palakkad", cityHindi: "पालक्कड़", districts: [{ district: "Palakkad", districtHindi: "पालक्कड़" }] },
      { city: "Kannur", cityHindi: "कन्नूर", districts: [{ district: "Kannur", districtHindi: "कन्नूर" }] },
      { city: "Kottayam", cityHindi: "कोट्टायम", districts: [{ district: "Kottayam", districtHindi: "कोट्टायम" }] },
      { city: "Alappuzha", cityHindi: "अलप्पुझा", districts: [{ district: "Alappuzha", districtHindi: "अलप्पुझा" }] },
      { city: "Malappuram", cityHindi: "मलप्पुरम", districts: [{ district: "Malappuram", districtHindi: "मलप्पुरम" }] }
    ]
  },
  {
    state: "Madhya Pradesh",
    stateHindi: "मध्य प्रदेश",
    cities: [
      { city: "Bhopal", cityHindi: "भोपाल", districts: [{ district: "Bhopal", districtHindi: "भोपाल" }] },
      { city: "Indore", cityHindi: "इंदौर", districts: [{ district: "Indore", districtHindi: "इंदौर" }] },
      { city: "Gwalior", cityHindi: "ग्वालियर", districts: [{ district: "Gwalior", districtHindi: "ग्वालियर" }] },
      { city: "Jabalpur", cityHindi: "जबलपुर", districts: [{ district: "Jabalpur", districtHindi: "जबलपुर" }] },
      { city: "Ujjain", cityHindi: "उज्जैन", districts: [{ district: "Ujjain", districtHindi: "उज्जैन" }] },
      { city: "Sagar", cityHindi: "सागर", districts: [{ district: "Sagar", districtHindi: "सागर" }] },
      { city: "Dewas", cityHindi: "देवास", districts: [{ district: "Dewas", districtHindi: "देवास" }] },
      { city: "Ratlam", cityHindi: "रतलाम", districts: [{ district: "Ratlam", districtHindi: "रतलाम" }] },
      { city: "Vidisha", cityHindi: "विदिशा", districts: [{ district: "Vidisha", districtHindi: "विदिशा" }] },
      { city: "Morena", cityHindi: "मोरैना", districts: [{ district: "Morena", districtHindi: "मोरैना" }] },
      { city: "Shivpuri", cityHindi: "शिवपुरी", districts: [{ district: "Shivpuri", districtHindi: "शिवपुरी" }] }
    ]
  },
  {
    state: "Maharashtra",
    stateHindi: "महाराष्ट्र",
    cities: [
      { city: "Mumbai", cityHindi: "मुंबई", districts: [{ district: "Mumbai", districtHindi: "मुंबई" }] },
      { city: "Pune", cityHindi: "पुणे", districts: [{ district: "Pune", districtHindi: "पुणे" }] },
      { city: "Nagpur", cityHindi: "नागपुर", districts: [{ district: "Nagpur", districtHindi: "नागपुर" }] },
      { city: "Thane", cityHindi: "ठाणे", districts: [{ district: "Thane", districtHindi: "ठाणे" }] },
      { city: "Nashik", cityHindi: "नाशिक", districts: [{ district: "Nashik", districtHindi: "नाशिक" }] },
      { city: "Aurangabad", cityHindi: "औरंगाबाद", districts: [{ district: "Aurangabad", districtHindi: "औरंगाबाद" }] },
      { city: "Solapur", cityHindi: "सोलापुर", districts: [{ district: "Solapur", districtHindi: "सोलापुर" }] },
      { city: "Kolhapur", cityHindi: "कोल्हापुर", districts: [{ district: "Kolhapur", districtHindi: "कोल्हापुर" }] },
      { city: "Amravati", cityHindi: "अमरावती", districts: [{ district: "Amravati", districtHindi: "अमरावती" }] },
      { city: "Latur", cityHindi: "लातूर", districts: [{ district: "Latur", districtHindi: "लातूर" }] },
      { city: "Jalgaon", cityHindi: "जलगांव", districts: [{ district: "Jalgaon", districtHindi: "जलगांव" }] },
      { city: "Ahmednagar", cityHindi: "अहमदनगर", districts: [{ district: "Ahmednagar", districtHindi: "अहमदनगर" }] },
      { city: "Akola", cityHindi: "अकोला", districts: [{ district: "Akola", districtHindi: "अकोला" }] },
      { city: "Chandrapur", cityHindi: "चंद्रपुर", districts: [{ district: "Chandrapur", districtHindi: "चंद्रपुर" }] }
    ]
  },
  {
    state: "Manipur",
    stateHindi: "मणिपुर",
    cities: [
      { city: "Imphal", cityHindi: "इंफाल", districts: [{ district: "Imphal", districtHindi: "इंफाल" }] },
      { city: "Thoubal", cityHindi: "थौबल", districts: [{ district: "Thoubal", districtHindi: "थौबल" }] },
      { city: "Churachandpur", cityHindi: "चुराचंदपुर", districts: [{ district: "Churachandpur", districtHindi: "चुराचंदपुर" }] },
      { city: "Bishnupur", cityHindi: "बिष्णुपुर", districts: [{ district: "Bishnupur", districtHindi: "बिष्णुपुर" }] },
      { city: "Ukhrul", cityHindi: "उख्रुल", districts: [{ district: "Ukhrul", districtHindi: "उख्रुल" }] }
    ]
  },
  {
    state: "Meghalaya",
    stateHindi: "मेघालय",
    cities: [
      { city: "Shillong", cityHindi: "शिलॉंग", districts: [{ district: "Shillong", districtHindi: "शिलॉंग" }] },
      { city: "Tura", cityHindi: "तुरा", districts: [{ district: "West Garo Hills", districtHindi: "वेस्ट गैरो हिल्स" }] },
      { city: "Nongpoh", cityHindi: "नोंगपोह", districts: [{ district: "Ri-Bhoi", districtHindi: "री-भोई" }] },
      { city: "Jowai", cityHindi: "जोवाई", districts: [{ district: "Jaintia Hills", districtHindi: "जैंटिया हिल्स" }] }
    ]
  },
  {
    state: "Mizoram",
    stateHindi: "मिजोरम",
    cities: [
      { city: "Aizawl", cityHindi: "ऐजॉल", districts: [{ district: "Aizawl", districtHindi: "ऐजॉल" }] },
      { city: "Lunglei", cityHindi: "लुंगलेई", districts: [{ district: "Lunglei", districtHindi: "लुंगलेई" }] },
      { city: "Champhai", cityHindi: "चामफै", districts: [{ district: "Champhai", districtHindi: "चामफै" }] }
    ]
  },
  {
    state: "Nagaland",
    stateHindi: "नगालैंड",
    cities: [
      { city: "Kohima", cityHindi: "कोहिमा", districts: [{ district: "Kohima", districtHindi: "कोहिमा" }] },
      { city: "Dimapur", cityHindi: "डिमापुर", districts: [{ district: "Dimapur", districtHindi: "डिमापुर" }] },
      { city: "Mokokchung", cityHindi: "मोकोकचुंग", districts: [{ district: "Mokokchung", districtHindi: "मोकोकचुंग" }] },
      { city: "Mon", cityHindi: "मोन", districts: [{ district: "Mon", districtHindi: "मोन" }] },
      { city: "Tuensang", cityHindi: "तुएन्सांग", districts: [{ district: "Tuensang", districtHindi: "तुएन्सांग" }] }
    ]
  },
  {
    state: "Odisha",
    stateHindi: "ओडिशा",
    cities: [
      { city: "Bhubaneswar", cityHindi: "भुवनेश्वर", districts: [{ district: "Khordha", districtHindi: "खोरधा" }] },
      { city: "Cuttack", cityHindi: "कटक", districts: [{ district: "Cuttack", districtHindi: "कटक" }] },
      { city: "Rourkela", cityHindi: "राउरकेला", districts: [{ district: "Sundergarh", districtHindi: "सुंदरगढ़" }] },
      { city: "Sambalpur", cityHindi: "साम्बलपुर", districts: [{ district: "Sambalpur", districtHindi: "साम्बलपुर" }] },
      { city: "Berhampur", cityHindi: "बेरहामपुर", districts: [{ district: "Ganjam", districtHindi: "गंजम" }] },
      { city: "Puri", cityHindi: "पुरी", districts: [{ district: "Puri", districtHindi: "पुरी" }] },
      { city: "Balasore", cityHindi: "बालासोर", districts: [{ district: "Balasore", districtHindi: "बालासोर" }] },
      { city: "Jharsuguda", cityHindi: "झारसुगुड़ा", districts: [{ district: "Jharsuguda", districtHindi: "झारसुगुड़ा" }] },
      { city: "Angul", cityHindi: "अंगुल", districts: [{ district: "Angul", districtHindi: "अंगुल" }] },
      { city: "Bhadrak", cityHindi: "भद्रक", districts: [{ district: "Bhadrak", districtHindi: "भद्रक" }] }
    ]
  },
  {
    state: "Punjab",
    stateHindi: "पंजाब",
    cities: [
      { city: "Chandigarh", cityHindi: "चंडीगढ़", districts: [{ district: "Chandigarh", districtHindi: "चंडीगढ़" }] },
      { city: "Ludhiana", cityHindi: "लुधियाना", districts: [{ district: "Ludhiana", districtHindi: "लुधियाना" }] },
      { city: "Amritsar", cityHindi: "अमृतसर", districts: [{ district: "Amritsar", districtHindi: "अमृतसर" }] },
      { city: "Jalandhar", cityHindi: "जालंधर", districts: [{ district: "Jalandhar", districtHindi: "जालंधर" }] },
      { city: "Patiala", cityHindi: "पटियाला", districts: [{ district: "Patiala", districtHindi: "पटियाला" }] },
      { city: "Bathinda", cityHindi: "बठिंडा", districts: [{ district: "Bathinda", districtHindi: "बठिंडा" }] },
      { city: "Mohali", cityHindi: "मोहाली", districts: [{ district: "Mohali", districtHindi: "मोहाली" }] },
      { city: "Hoshiarpur", cityHindi: "होशियारपुर", districts: [{ district: "Hoshiarpur", districtHindi: "होशियारपुर" }] },
      { city: "Moga", cityHindi: "मोङा", districts: [{ district: "Moga", districtHindi: "मोङा" }] },
      { city: "Firozpur", cityHindi: "फिरोजपुर", districts: [{ district: "Firozpur", districtHindi: "फिरोजपुर" }] }
    ]
  },
  {
    state: "Rajasthan",
    stateHindi: "राजस्थान",
    cities: [
      { city: "Ajmer", cityHindi: "अजमेर", districts: [{ district: "Ajmer", districtHindi: "अजमेर" }] },
      { city: "Bharatpur", cityHindi: "भरतपुर", districts: [{ district: "Bharatpur", districtHindi: "भरतपुर" }] },
      { city: "Bikaner", cityHindi: "बीकानेर", districts: [{ district: "Bikaner", districtHindi: "बीकानेर" }] },
      { city: "Jaipur", cityHindi: "जयपुर", districts: [{ district: "Jaipur", districtHindi: "जयपुर" }] },
      { city: "Jodhpur", cityHindi: "जोधपुर", districts: [{ district: "Jodhpur", districtHindi: "जोधपुर" }] },
      { city: "Udaipur", cityHindi: "उदयपुर", districts: [{ district: "Udaipur", districtHindi: "उदयपुर" }] },
      { city: "Kota", cityHindi: "कोटा", districts: [{ district: "Kota", districtHindi: "कोटा" }] },
      { city: "Bundi", cityHindi: "बूंदी", districts: [{ district: "Bundi", districtHindi: "बूंदी" }] },
      { city: "Alwar", cityHindi: "अलवर", districts: [{ district: "Alwar", districtHindi: "अलवर" }] },
      { city: "Sikar", cityHindi: "सिकर", districts: [{ district: "Sikar", districtHindi: "सिकर" }] },
      { city: "Chittorgarh", cityHindi: "चित्तौड़गढ़", districts: [{ district: "Chittorgarh", districtHindi: "चित्तौड़गढ़" }] },
      { city: "Dausa", cityHindi: "दौसा", districts: [{ district: "Dausa", districtHindi: "दौसा" }] },
      { city: "Pali", cityHindi: "पाली", districts: [{ district: "Pali", districtHindi: "पाली" }] },
      { city: "Sirohi", cityHindi: "सीरोही", districts: [{ district: "Sirohi", districtHindi: "सीरोही" }] },
      { city: "Barmer", cityHindi: "बारमेर", districts: [{ district: "Barmer", districtHindi: "बारमेर" }] },
      { city: "Baran", cityHindi: "बारन", districts: [{ district: "Baran", districtHindi: "बारन" }] },
      { city: "Banswara", cityHindi: "बांसवाड़ा", districts: [{ district: "Banswara", districtHindi: "बांसवाड़ा" }] },
      { city: "Dungarpur", cityHindi: "डूंगरपुर", districts: [{ district: "Dungarpur", districtHindi: "डूंगरपुर" }] },
      { city: "Pratapgarh", cityHindi: "प्रतापगढ़", districts: [{ district: "Pratapgarh", districtHindi: "प्रतापगढ़" }] }
    ]
  },
  {
    state: "Sikkim",
    stateHindi: "सिक्किम",
    cities: [
      { city: "Gangtok", cityHindi: "गंगटोक", districts: [{ district: "Gangtok", districtHindi: "गंगटोक" }] },
      { city: "Namchi", cityHindi: "नाम्ची", districts: [{ district: "Namchi", districtHindi: "नाम्ची" }] },
      { city: "Gyalshing", cityHindi: "ग्यालशिंग", districts: [{ district: "Gyalshing", districtHindi: "ग्यालशिंग" }] }
    ]
  },
  {
    state: "Tamil Nadu",
    stateHindi: "तमिलनाडु",
    cities: [
      { city: "Chennai", cityHindi: "चेन्नई", districts: [{ district: "Chennai", districtHindi: "चेन्नई" }] },
      { city: "Coimbatore", cityHindi: "कोयंबटूर", districts: [{ district: "Coimbatore", districtHindi: "कोयंबटूर" }] },
      { city: "Madurai", cityHindi: "मदुरै", districts: [{ district: "Madurai", districtHindi: "मदुरै" }] },
      { city: "Tiruchirappalli", cityHindi: "तिरुचिरापल्ली", districts: [{ district: "Tiruchirappalli", districtHindi: "तिरुचिरापल्ली" }] },
      { city: "Salem", cityHindi: "सालेम", districts: [{ district: "Salem", districtHindi: "सालेम" }] },
      { city: "Tirunelveli", cityHindi: "तिरुनेलवेली", districts: [{ district: "Tirunelveli", districtHindi: "तिरुनेलवेली" }] },
      { city: "Tiruppur", cityHindi: "तिरुप्पुर", districts: [{ district: "Tiruppur", districtHindi: "तिरुप्पुर" }] },
      { city: "Erode", cityHindi: "एरोड", districts: [{ district: "Erode", districtHindi: "एरोड" }] },
      { city: "Vellore", cityHindi: "वेल्लोर", districts: [{ district: "Vellore", districtHindi: "वेल्लोर" }] },
      { city: "Thoothukudi", cityHindi: "थूठुकुडी", districts: [{ district: "Thoothukudi", districtHindi: "थूठुकुडी" }] },
      { city: "Tiruvallur", cityHindi: "तिरुवल्लूर", districts: [{ district: "Tiruvallur", districtHindi: "तिरुवल्लूर" }] }
    ]
  },
  {
    state: "Telangana",
    stateHindi: "तेलंगाना",
    cities: [
      { city: "Hyderabad", cityHindi: "हैदराबाद", districts: [{ district: "Hyderabad", districtHindi: "हैदराबाद" }] },
      { city: "Warangal", cityHindi: "वरंगल", districts: [{ district: "Warangal", districtHindi: "वरंगल" }] },
      { city: "Nizamabad", cityHindi: "निजामाबाद", districts: [{ district: "Nizamabad", districtHindi: "निजामाबाद" }] },
      { city: "Karimnagar", cityHindi: "करीमनगर", districts: [{ district: "Karimnagar", districtHindi: "करीमनगर" }] },
      { city: "Khammam", cityHindi: "खम्मम", districts: [{ district: "Khammam", districtHindi: "खम्मम" }] },
      { city: "Ramagundam", cityHindi: "रामगुंडम", districts: [{ district: "Ramagundam", districtHindi: "रामगुंडम" }] },
      { city: "Ranga Reddy", cityHindi: "रांगा रेड्डी", districts: [{ district: "Ranga Reddy", districtHindi: "रांगा रेड्डी" }] }
    ]
  },
  {
    state: "Tripura",
    stateHindi: "त्रिपुरा",
    cities: [
      { city: "Agartala", cityHindi: "अगर्तला", districts: [{ district: "Agartala", districtHindi: "अगर्तला" }] },
      { city: "Udaipur", cityHindi: "उदयपुर", districts: [{ district: "Udaipur", districtHindi: "उदयपुर" }] },
      { city: "Kailasahar", cityHindi: "कैलासाहार", districts: [{ district: "Kailasahar", districtHindi: "कैलासाहार" }] },
      { city: "Dharmanagar", cityHindi: "धर्मनगर", districts: [{ district: "Dharmanagar", districtHindi: "धर्मनगर" }] },
      { city: "Amarpur", cityHindi: "अमरपुर", districts: [{ district: "Amarpur", districtHindi: "अमरपुर" }] }
    ]
  },
  {
    state: "Uttar Pradesh",
    stateHindi: "उत्तर प्रदेश",
    cities: [
      { city: "Lucknow", cityHindi: "लखनऊ", districts: [{ district: "Lucknow", districtHindi: "लखनऊ" }] },
      { city: "Kanpur", cityHindi: "कानपुर", districts: [{ district: "Kanpur", districtHindi: "कानपुर" }] },
      { city: "Varanasi", cityHindi: "वाराणसी", districts: [{ district: "Varanasi", districtHindi: "वाराणसी" }] },
      { city: "Agra", cityHindi: "आगरा", districts: [{ district: "Agra", districtHindi: "आगरा" }] },
      { city: "Ghaziabad", cityHindi: "गाजियाबाद", districts: [{ district: "Ghaziabad", districtHindi: "गाजियाबाद" }] },
      { city: "Meerut", cityHindi: "मेरीट", districts: [{ district: "Meerut", districtHindi: "मेरीट" }] },
      { city: "Noida", cityHindi: "नोएडा", districts: [{ district: "Noida", districtHindi: "नोएडा" }] },
      { city: "Allahabad (Prayagraj)", cityHindi: "इलाहाबाद (प्रयागराज)", districts: [{ district: "Prayagraj", districtHindi: "प्रयागराज" }] },
      { city: "Bareilly", cityHindi: "बरेली", districts: [{ district: "Bareilly", districtHindi: "बरेली" }] },
      { city: "Moradabad", cityHindi: "मुरादाबाद", districts: [{ district: "Moradabad", districtHindi: "मुरादाबाद" }] },
      { city: "Aligarh", cityHindi: "अलीगढ़", districts: [{ district: "Aligarh", districtHindi: "अलीगढ़" }] },
      { city: "Saharanpur", cityHindi: "सहारनपुर", districts: [{ district: "Saharanpur", districtHindi: "सहारनपुर" }] },
      { city: "Gorakhpur", cityHindi: "गोरखपुर", districts: [{ district: "Gorakhpur", districtHindi: "गोरखपुर" }] },
      { city: "Etawah", cityHindi: "एतावा", districts: [{ district: "Etawah", districtHindi: "एतावा" }] }
    ]
  },
  {
    state: "Uttarakhand",
    stateHindi: "उत्तराखंड",
    cities: [
      { city: "Dehradun", cityHindi: "देहरादून", districts: [{ district: "Dehradun", districtHindi: "देहरादून" }] },
      { city: "Nainital", cityHindi: "नैनीताल", districts: [{ district: "Nainital", districtHindi: "नैनीताल" }] },
      { city: "Haridwar", cityHindi: "हरिद्वार", districts: [{ district: "Haridwar", districtHindi: "हरिद्वार" }] },
      { city: "Roorkee", cityHindi: "रूरकी", districts: [{ district: "Roorkee", districtHindi: "रूरकी" }] },
      { city: "Haldwani", cityHindi: "हल्द्वानी", districts: [{ district: "Nainital", districtHindi: "नैनीताल" }] },
      { city: "Rudrapur", cityHindi: "रूद्रपुर", districts: [{ district: "Udham Singh Nagar", districtHindi: "उधम सिंह नगर" }] },
      { city: "Almora", cityHindi: "अलमौरा", districts: [{ district: "Almora", districtHindi: "अलमौरा" }] },
      { city: "Ranikhet", cityHindi: "रानीखेत", districts: [{ district: "Nainital", districtHindi: "नैनीताल" }] }
    ]
  },
  {
    state: "West Bengal",
    stateHindi: "पश्चिम बंगाल",
    cities: [
      { city: "Kolkata", cityHindi: "कोलकाता", districts: [{ district: "Kolkata", districtHindi: "कोलकाता" }] },
      { city: "Howrah", cityHindi: "हावड़ा", districts: [{ district: "Howrah", districtHindi: "हावड़ा" }] },
      { city: "Darjeeling", cityHindi: "दार्जिलिंग", districts: [{ district: "Darjeeling", districtHindi: "दार्जिलिंग" }] },
      { city: "Siliguri", cityHindi: "सिलिगुड़ी", districts: [{ district: "Darjeeling", districtHindi: "दार्जिलिंग" }] },
      { city: "Durgapur", cityHindi: "दुर्गापुर", districts: [{ district: "Durgapur", districtHindi: "दुर्गापुर" }] },
      { city: "Asansol", cityHindi: "आसनसोल", districts: [{ district: "Asansol", districtHindi: "आसनसोल" }] },
      { city: "Jalpaiguri", cityHindi: "जलपाईगुड़ी", districts: [{ district: "Jalpaiguri", districtHindi: "जलपाईगुड़ी" }] },
      { city: "Murshidabad", cityHindi: "मुर्शिदाबाद", districts: [{ district: "Murshidabad", districtHindi: "मुर्शिदाबाद" }] },
      { city: "Bardhaman", cityHindi: "बर्धमान", districts: [{ district: "Bardhaman", districtHindi: "बर्धमान" }] },
      { city: "Malda", cityHindi: "मालदा", districts: [{ district: "Malda", districtHindi: "मालदा" }] },
      { city: "Kharagpur", cityHindi: "खड़गपुर", districts: [{ district: "Paschim Medinipur", districtHindi: "पश्चिम मेदिनीपुर" }] },
      { city: "Hooghly", cityHindi: "हुगली", districts: [{ district: "Hooghly", districtHindi: "हुगली" }] }
    ]
  }
];

