import {
  Grid,
  Button,
  Typography,
  Divider,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  Pagination,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useId, useRef, useState } from "react";
import Filter from "../../../../component/Filter";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BlockPopUp from "../../../../component/BlockPopUp";
import NoDataFound from "../../../../component/NoDataFound";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockIcon from "@mui/icons-material/Block";
import toast from "react-hot-toast";
import { Delete, LensTwoTone } from "@mui/icons-material";
import DeletePopUp from "../../../../component/DeletePopUp";
import { getAPIHandler, putAPIHandler } from "../../../../config/service";
import CircularProgressBox from "../../../../component/CircularProgressBox";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
const tableHeadData = [
  "S.No",
  "Article Name",
  "Article Type",
  "Date & Time",
  "Action",
];

const ArticleCategory = () => {
  const [open, setOpen] = useState(false);
  const [isDataUpdate, setIsDataUpdate] = useState(false);
  const [listData, setListData] = useState([]);
  const [isDataLoading, setisDataLoading] = useState(true);
  const navigate = useNavigate();
  const [currentBusinessOwnerData, setCurrentBusinessOwnerData] = useState({});
  const [csvData, setCsvData] = useState([]);
  const csvRef = useRef();
  const [isCsvDataLoading, setIsCsvDataLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const { state } = useLocation();
 
  const {categoryId} = useParams()

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    search: "",
    fromDate: "",
    toDate: "",
  });

  async function blockhandler() {
    console.log("alsgfa", currentBusinessOwnerData);
    const payLoadToSend = {
      articleId: currentBusinessOwnerData?.id,
      status: currentBusinessOwnerData?.status == "BLOCK" ? "ACTIVE" : "BLOCK",
    };
    const response = await putAPIHandler({
      endPoint: "updateCategoryArticle",
      dataToSend: payLoadToSend,
    });
    if (!response) {
      return;
    }
    setIsDataUpdate((p) => !p);
  }

  async function initialData(params) {
    const filterToSend = filter;
    setisDataLoading(true);
    const response = await getAPIHandler({
      endPoint: "listAllCategoryArticle",
      paramsData: {
        categoryId: categoryId,
        categoryId:categoryId,
        ...Object.fromEntries(
          Object.entries(filterToSend).filter(([_, v]) => v != "")
        ),
      },
    });
    if (!response || response.status !== 200) {
      setisDataLoading(false);
      return;
    }
    const articleList = response?.data?.result?.articleList || [];
    const totalPages = response?.data?.result?.totalPages || 1;

    setListData([]);
    setListData(articleList);
    setTotalPages(totalPages);

    setisDataLoading(false);
  }

  useEffect(() => {
    initialData();
  }, [isDataUpdate, filter]);

  async function csvDownloder() {
    setIsCsvDataLoading(true);
    const paramsToSend = {
      categoryId: categoryId,
    };
    const res = await getAPIHandler({
      endPoint: "listAllCategoryArticle",
      paramsData: paramsToSend,
    });
    if (!res) {
      setIsCsvDataLoading(false);
      return;
    }
    let dataToBeDownload = [];
    const tableHeadDataToCsv = [
      "S.No",
      "Article Name",
      "Article Type",
      "Date & Time",
    ];
    dataToBeDownload.push(tableHeadDataToCsv);
    dataToBeDownload = [
      ...dataToBeDownload,
      ...res?.data?.result?.articleList.map((e, i) => [
        i + 1,
        e?.articleTitle_en ? e?.articleTitle_en : "-",
        e?.articleType_en ? e?.articleType_en : "-",
        e?.createdAt ? dayjs(e?.createdAt).format("lll") : "-",
      ]),
    ];
    setCsvData(dataToBeDownload);
    const csvBlob = new Blob(
      [dataToBeDownload.map((row) => row.join(",")).join("\n")],
      { type: "text/csv" }
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(csvBlob);
    downloadLink.download = "article_data.csv";

    setTimeout(() => {
      setIsCsvDataLoading(false);
      downloadLink.click();
    }, 1000);
  }

  const deleteHandler = async () => {
    
    const payLoadToSend = {
      articleId: currentBusinessOwnerData?.id,
      status: "DELETE",
    };
    const response = await putAPIHandler({
      endPoint: "updateCategoryArticle",
      dataToSend: payLoadToSend,
    });
    if (!response) {
      return;
    }
    setIsDataUpdate((p) => !p);
  };

  return (
    <Grid container>
      <Grid
        container
        item
        style={{ backgroundColor: "#ffffff" }}
        sx={{ mt: { xs: 6, md: 2, lg: 0 } }}
      >
        <Grid item mb={4} container justifyContent={"space-between"} alignItems={"center"}>
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: 1,
      overflow: "hidden",
      flex: 1, // Ensure it takes available space
      minWidth: 0, // Required for ellipsis to work
    }}
  >
    <IconButton onClick={() => navigate(-1)} sx={{ color: "#2A73E0" }}>
      <ArrowCircleLeftIcon sx={{ fontSize: "30px" }} />
    </IconButton>

    <Typography
      sx={{
        display: "flex",
        alignItems: "center",
        whiteSpace: "pre-line" ,
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "100%", // Ensures text truncates within available space
      }}
      title={`Article Management - ${state?.value?.categoryName_en}`}
    >
      <Typography
        variant="h5"
        sx={{
          color: "#262626",
          fontWeight: 600,
          display: "inline",
        }}
      >
        Article Management
      </Typography>
      <Box
        component="span"
        sx={{
          fontWeight: 400,
          fontSize: "1rem",
          color: "#666",
          marginLeft: 0.5,
          display: "inline",
        }}
      >
        - {state?.value?.categoryName_en}
      </Box>
    </Typography>
  </Box>

  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: 2, // Adjust spacing between buttons
      flexShrink: 0, // Prevent buttons from shrinking
    }}
  >
    <Tooltip title="Download CSV">
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#1D60B3",
          px: 4,
          py: 1.5,
          borderRadius: "24px",
          whiteSpace: "pre-line" , // Prevent button text from wrapping
        }}
        onClick={csvDownloder}
        loading={isCsvDataLoading}
      >
        Download CSV
      </Button>
    </Tooltip>

    <Button
      variant="outlined"
      sx={{
        color: "#2A73E0",
        border: "1px solid #2A73E0",
        px: 3,
        py: 1.3,
        whiteSpace: "pre-line" , // Prevent button text from wrapping
      }}
      onClick={() => {
        navigate("/informative-content/view-category/add-article", {
          state: { categoryId:categoryId},
        });
      }}
    >
      <AddIcon />
      Article
    </Button>
  </Box>
</Grid>


        <Grid item xs={11} alignItems={"center"}>
          <Divider sx={{ background: "#DFDFDF" }} />
        </Grid>

        <Filter setFilter={setFilter} />

        <TableContainer
          className="removeScrollbar"
          // ref={scrollContainer.ref}
          component={Paper}
          sx={{
            mt: 4,
            overflowX: "scroll",
            width: "100%",
            // minHeight: "520px",
          }}
        >
          <Table sx={{ minWidth: 1200 }}>
            {isDataLoading ? (
              <>
                <CircularProgressBox />
              </>
            ) : !isDataLoading && listData.length == 0 ? (
              <>
                <NoDataFound message={"No Informative Content Found"} />
              </>
            ) : (
              <>
                <TableHead
                  sx={{
                    bgcolor: "#2A73E0",
                  }}
                >
                  <TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        padding: "4px",
                        height: "45px",
                      },
                    }}
                  >
                    {tableHeadData.map((e, i) => (
                      <TableCell key={i} align="center" sx={{ color: "white" }}>
                        {e}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listData.map((data, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "& .MuiTableCell-root": {
                          padding: "4px",
                        },
                      }}
                    >
                      <TableCell align="center" scope="row">
                        {currentPage ? (currentPage - 1) * 10 + (i + 1) : i + 1}
                      </TableCell>

                      <Tooltip
                        title={
                          data?.articleTitle_en ? data?.articleTitle_en : "-"
                        }
                      >
                        <TableCell align="center" scope="row">
                          {data?.articleTitle_en
                            ? data?.articleTitle_en.length > 20
                              ? data?.articleTitle_en.slice(0, 20) + "..."
                              : data?.articleTitle_en
                            : "-"}
                        </TableCell>
                      </Tooltip>

                      <Tooltip
                        title={
                          data?.articleType_en ? data?.articleType_en : "-"
                        }
                      >
                        <TableCell align="center" scope="row">
                          {data?.articleType_en
                            ? data?.articleType_en.length > 20
                              ? data?.articleType_en
                                  .slice(0, 20)
                                  .charAt(0)
                                  .toUpperCase() +
                                data?.articleType_en
                                  .slice(0, 20)
                                  .slice(1)
                                  .toLowerCase() +
                                "..."
                              : data?.articleType_en.charAt(0).toUpperCase() +
                                data?.articleType_en.slice(1).toLowerCase()
                            : "-"}
                        </TableCell>
                      </Tooltip>

                      <TableCell align="center" scope="row">
                        {dayjs(data?.createdAt).format("lll")}
                      </TableCell>

                      <TableCell align="center" scope="row">
                        <Tooltip title={"View"}>
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              navigate(
                                "/informative-content/view-category/view-article",
                                {
                                  state: { value: data },
                                }
                              );
                            }}
                            sx={{ color: "#5D9FED" }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          title={data?.status == "BLOCK" ? "Unblock" : "Block"}
                        >
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              setCurrentBusinessOwnerData(data);
                              setOpen(true);
                            }}
                            sx={{
                              color: data?.status == "BLOCK" ? "red" : "green",
                            }}
                          >
                            <BlockIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Delete"}>
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              setCurrentBusinessOwnerData(data);
                              setDeletePopUp(true);
                            }}
                            sx={{
                              color: "red",
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Edit owner profile"}>
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              navigate(
                                "/informative-content/view-category/edit-article",
                                {
                                  state: { value: data },
                                }
                              );
                            }}
                            sx={{ color: "#2B2B52" }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </Grid>

      <Grid item container justifyContent={"flex-end"} mt={4}>
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            defaultPage={filter.page}
            onChange={(e, page) => {
              const updatedFilter = { ...filter, page: page };
              setCurrentPage(page);
              setFilter(updatedFilter);
            }}
            sx={{ color: "#681e65" }}
          />
        )}
      </Grid>

      <BlockPopUp
        open={open}
        setOpen={setOpen}
        funcToExecute={blockhandler}
        isBlocked={currentBusinessOwnerData?.status == "BLOCK" ? false : true}
      />

      <DeletePopUp
        funcToExecute={deleteHandler}
        open={deletePopUp}
        setOpen={setDeletePopUp}
      />
    </Grid>
  );
};

export default ArticleCategory;
