import React, { useRef, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Badge,
  IconButton,
  Avatar,
  FormHelperText,
  Select,
  MenuItem,
  CircularProgress
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { caregiverSchema } from "../../../schema/index";
import EditIcon from "@mui/icons-material/Edit";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { statesData } from "../../../data/Data";
import { postAPIHandler, putAPIHandler } from "../../../config/service";
import { fileUploadHandler } from "../../../utils/fileUploadHandler";
import { transliterateToHindi } from "../../../config/service";

const EditCareGiver = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const caregiverData = state?.value;
  const [image, setImage] = useState(caregiverData?.profileUrl || "");
  const [submitload, setsubmitload]=useState()


  const [isLoading, setIsLoading] = useState(false);
  const imageFieldRef = useRef();
  const [imageResolutionError, setImageResolutionError] = useState("");
  const [selectedState, setSelectedState] = useState(state?.value?.state_en);
  const [selectedStateHindi, setSelectedStateHindi] = useState(
    state?.value?.state_hi
  );
  const [selectedCity, setSelectedCity] = useState(state?.value?.city_en);
  const [selectedCityHindi, setSelectedCityHindi] = useState(
    state?.value?.city_hi
  );
  const [selectedDistrict, setSelectedDistrict] = useState(
    state?.value?.district_en
  );
  const [selectedDistrictHindi, setSelectedDistrictHindi] = useState(
    state?.value?.state_hi
  );


  const fullName = caregiverData?.fullName_en || "";
  const [firstName, ...rest] = fullName.split(" ");
  const lastName = rest.join(" ");

  const fullNameHi = caregiverData?.fullName_hi || "";
  const [firstNameHi, ...restHi] = fullNameHi.split(" ");
  const lastNameHi = restHi.join(" ");

  const fileUploader = async (event, setFieldValue) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (!file) {
      setImageResolutionError("");
      setFieldValue("thumbnailImage", null);
      setFieldValue("profileUrl", null);
      setIsLoading(false);
      return;
    }

    // Validate file type
    if (
      !["image/jpeg", "image/png", "image/webp",].includes(file.type)
    ) {
      setImageResolutionError(
        "Unsupported file type. Please upload a JPG, PNG, WebP image."
      );
      setIsLoading(false);
      return;
    }

    // Validate file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setImageResolutionError(
        "The file is too large. Please upload a file smaller than 2MB."
      );
      setIsLoading(false);

      return;
    }

    const img = new Image();
    const reader = new FileReader();

    reader.onloadend = async () => {
      img.onload = async () => {
        // Validate image resolution (at least 180px x 180px)
        if (img.width < 180 || img.height < 180) {
          setImageResolutionError("Image must be at least 180px x 180px.");
          setIsLoading(false);

          return;
        }

        setImageResolutionError("");
        setImage(reader.result); // Set image for preview

        // Upload image to the server
        const uploadedUrl = await fileUploadHandler(file);

        setFieldValue("thumbnailImage", uploadedUrl);
        console.log("Uploaded URL:", uploadedUrl);

        if (uploadedUrl) {
          setFieldValue("profileUrl", uploadedUrl);
          setImage(uploadedUrl);
          setIsLoading(false);
        } else {
          setImageResolutionError("Failed to upload image. Please try again.");
          setIsLoading(false);
        }
      };

      img.src = reader.result;
    };

    reader.readAsDataURL(file);
  };

  const getCities = (state) => {
    console.log("State passed:", state);
    console.log(
      "States in data:",
      statesData.map((s) => s.state)
    );
    return statesData.find((s) => s.state === state)?.cities || [];
  };

  const getCitiesHindi = (stateHindi) =>
    statesData.find((s) => s.stateHindi === stateHindi)?.cities || [];
  console.log(getCities(), "uiu");

  const getDistricts = (cityName) => {
    // Get the list of cities for the selected state (using the getCities function)
    const cities = getCities(selectedState);
    console.log(cities, "dgdfgdf");
    // Find the city in the list and return its districts
    // If no match is found, return an empty array
    const city = cities.find((c) => c.city === cityName);
    return city ? city.districts : [];
  };

  const getDistrictsHindi = (cityHindi) =>
    getCitiesHindi(selectedStateHindi).find((c) => c.cityHindi === cityHindi)
      ?.districts || [];
  console.log("getDistricts: ", getDistricts());


  const onSubmit = async (values) => {
    setsubmitload(true)
    console.log(values, "values");

    const payloadToSend = Object.fromEntries(
      Object.entries({
        userId: caregiverData?.id,
        fullName_en: `${values.firstName} ${values.lastName}`,
        fullName_hi: `${values.firstNameHindi} ${values.lastNameHindi}`,
        mobileNumber_en: values.phoneNumber,
        mobileNumber_hi: values.phoneNumberHindi,
        countryCode_en: "+91",
        countryCode_hi: "+91",
        email_en: values.email,
        email_hi: values.emailHindi,
        profileUrl: image,
        state_en: values.state,
        state_hi: values.stateHindi,
        district_en: values.district,
        district_hi: values.districtHindi,
        city_en: values.city,
        city_hi: values.cityHindi,
        address_en: values.address,
        address_hi: values.addressHindi,
      }).filter(([_, value]) => value) // Filter out keys with falsy values
    );
    try {
      const response = await putAPIHandler({
        endPoint: "updateUserDetails",
        dataToSend: payloadToSend,
      });
      console.log(response, "response");

      if (response && response.data.responseCode === 200) {
        navigate("/caregiver-management");
      } else {
        console.error("User already exists.");
      }
    } catch (error) {
      console.error("Error:", error);
    }finally{
      setsubmitload(false)
    }
  };
  const style = {
    textfield: {
      "& .MuiInputBase-root": {
        padding: "12px 20px",
        borderRadius: "8px",
       
        backgroundColor: "#fff",
      },
      "& .MuiInputBase-root:hover": {
        backgroundColor: "#f4f4f4",
        borderColor: "#bbb",
      },
      "& .Mui-focused": {
        borderColor: "rgb(60,115,224)",
      },
      "& input": {
        padding: 0, // Adjust if necessary
      },
    },

    select:{
      "& .MuiInputBase-root": {
        padding: "12px 20px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
      },
      "& .MuiInputBase-root:hover": {
        backgroundColor: "#f4f4f4",
        borderColor: "#bbb",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgb(60,115,224)",
      },
      "& .MuiSelect-select": {
        padding: "12px 20px", // Matches the TextField padding
      },
      "& .Mui-disabled": {
        backgroundColor: "#f5f5f5",
        color: "#999",
      },
    }
  };

  return (
    <Grid container justifyContent={"center"}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0px 8%",
        }}
        item
        xs={12}
        mb={2}
      >
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: "#2A73E0",
          }}
        >
          <ArrowCircleLeftIcon
            sx={{
              fontSize: "30px",
            }}
          />
        </IconButton>
        <Typography color={"#262626"} fontWeight={"600"} variant="h5">
          Update Caregiver
        </Typography>
      </Grid>

      <Grid item container xs={12}>
        {" "}
        <Formik
          initialValues={{
            firstName: firstName || "",
            firstNameHindi: firstNameHi || "",
            lastName: lastName || "",
            lastNameHindi: lastNameHi || "",
            email: caregiverData?.email_en || "",
            emailHindi: caregiverData?.email_hi || "",
            phoneNumber: caregiverData?.mobileNumber_en || "",
            phoneNumberHindi: caregiverData?.mobileNumber_hi || "",
            address: caregiverData?.address_en || "",
            addressHindi: caregiverData?.address_hi || "",
            state: caregiverData?.state_en || "",
            stateHindi: caregiverData?.state_hi || "",
            city: caregiverData?.city_en || "",
            cityHindi: caregiverData?.city_hi || "",
            district: caregiverData?.district_en || "",
            districtHindi: caregiverData?.district_hi || "",
            thumbnailImage: caregiverData?.profileUrl || null,
          }}
          validationSchema={caregiverSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form
              style={{
                minWidth: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                item
                // sm={8}
                md={10}
                xs={12}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  p: "10px",
                }}
              >
               <Grid
                    container
                    item
                    sm={12}
                    lg={5}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <Grid
                      container
                      item
                      sm={12}
                      lg={5}
                      justifyContent={"center"}
                    >
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <IconButton
                            disabled={isLoading}
                            style={{ background: "#7B8788" }}
                            onClick={() => {
                              imageFieldRef.current?.click();
                            }}
                            sx={{ color: "#2B2B52" }}
                          >
                            <>
                              <input
                                ref={imageFieldRef}
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => fileUploader(e, setFieldValue)}
                              />
                              <EditIcon />
                            </>
                          </IconButton>
                        }
                      >
                        <Avatar
                          alt="Profile Image"
                          sx={{
                            height: 140,
                            width: 140,
                            opacity: isLoading ? 0.5 : 1,
                            transition: "opacity 0.3s",
                            position: "relative", // Ensure Badge is the reference for absolute positioning
                          }}
                          src={image}
                        />

                        {/* Loader */}
                        {isLoading && (
                          <CircularProgress
                            sx={{
                              position: "absolute",
                              top: "40%",
                              left: "36%",
                              transform: "translate(-50%, -50%)",
                             
                            }}
                          />
                        )}
                      </Badge>
                    </Grid>

                    <FormHelperText
                      sx={{
                        color: imageResolutionError ? "#d12f2f" : "#1f1f1f",
                        mt: 2,
                      }}
                    >
                      {imageResolutionError ||
                        "Image must be at least 180px x 180px"}
                    </FormHelperText>
                  </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  alignItems={"center"}
                  mt={2}
                  sx={{
                    gap: { sm: 1, lg: 6 },
                    justifyContent: { sm: "flex-start", md: "center" },
                  }}
                >
                  <Grid container item sm={12} lg={2}>
                    <label
                      style={{
                        whiteSpace: "pre-line" ,
                      }}
                    >
                      First Name (पहला नाम):
                    </label>
                  </Grid>

                  <Grid container item sm={12} lg={4}>
                    <TextField
                      value={values.firstName}
                      type="text"
                      name="firstName"
                      fullWidth
                      placeholder="Enter first name"
                      variant="outlined"
                      id="outlined-basic"
                      inputProps={{
                        maxLength: 80,
                      }}
                      FormHelperTextProps={{
                        style: { marginLeft: "0px" },
                      }}
                      sx={style.textfield}
                      disabled={isLoading}
                      onChange={async (e) => {
                        const { name, value } = e.target;
                        handleChange(e); // Update Formik value
                        if (name === "firstName") {
                          const transliteratedText = await transliterateToHindi(
                            value
                          );
                          setFieldValue("firstNameHindi", transliteratedText); // Update Hindi field
                        }
                      }}
                      onBlur={handleBlur}
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid>
                  <Grid container item sm={12} lg={4}>
                    <TextField
                      value={values.firstNameHindi}
                      type="text"
                      name="firstNameHindi"
                      fullWidth
                      placeholder="पहला नाम दर्ज करें"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 80,
                      }}
                      FormHelperTextProps={{
                        style: { marginLeft: "0px" },
                      }}
                      sx={style.textfield}
                      error={
                        touched.firstNameHindi && Boolean(errors.firstNameHindi)
                      }
                      helperText={
                        touched.firstNameHindi && errors.firstNameHindi
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  alignItems={"center"}
                  mt={2}
                  sx={{
                    gap: { sm: 1, lg: 6 },
                    justifyContent: { sm: "flex-start", md: "center" },
                  }}
                >
                  <Grid container item sm={12} lg={2}>
                    <label
                      style={{
                        whiteSpace: "pre-line" ,
                      }}
                    >
                      Last Name (अंतिम नाम):
                    </label>
                  </Grid>
                  <Grid container item sm={12} lg={4}>
                    <TextField
                      value={values.lastName}
                      type="text"
                      name="lastName"
                      fullWidth
                      placeholder="Enter last name"
                      variant="outlined"
                      id="outlined-basic"
                      inputProps={{
                        maxLength: 80,
                      }}
                      sx={style.textfield}
                      disabled={isLoading}
                      onChange={async (e) => {
                        const { name, value } = e.target;
                        handleChange(e); // Update Formik value
                        if (name === "lastName") {
                          const transliteratedText = await transliterateToHindi(
                            value
                          );
                          setFieldValue("lastNameHindi", transliteratedText); // Update Hindi field
                        }
                      }}
                      onBlur={handleBlur}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid>
                  <Grid container item sm={12} lg={4}>
                    <TextField
                      value={values.lastNameHindi}
                      type="text"
                      name="lastNameHindi"
                      fullWidth
                      placeholder="अंतिम नाम दर्ज करें"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={style.textfield}
                      inputProps={{
                        maxLength: 80,
                      }}
                      error={
                        touched.lastNameHindi && Boolean(errors.lastNameHindi)
                      }
                      helperText={touched.lastNameHindi && errors.lastNameHindi}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  alignItems={"center"}
                  mt={2}
                  sx={{
                    gap: { sm: 1, lg: 6 },
                    justifyContent: { sm: "flex-start", md: "center" },
                  }}
                >
                  <Grid container item sm={12} lg={2}>
                    <label
                      style={{
                        whiteSpace: "pre-line" ,
                      }}
                    >
                      Phone No. (फोन नंबर):
                    </label>
                  </Grid>
                  <Grid container item sm={12} lg={4}>
                    <TextField
                      value={values.phoneNumber}
                      type="tel"
                      name="phoneNumber"
                      fullWidth
                      placeholder="Enter phone number"
                      variant="outlined"
                      id="outlined-basic"
                      inputProps={{
                        maxLength: 10,

                        inputMode: "numeric", // Hints mobile devices to use numeric keyboard
                        pattern: "[0-9]*", // Ensures only numbers
                      }}
                      FormHelperTextProps={{
                        style: { marginLeft: "0px" },
                      }}
                      sx={style.textfield}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
                      }}
                      disabled={isLoading}
                      onChange={async (e) => {
                        const { name, value } = e.target;
                        handleChange(e); // Update Formik value
                        if (name === "phoneNumber") {
                          const transliteratedText = await transliterateToHindi(
                            value
                          );
                          setFieldValue("phoneNumberHindi", transliteratedText); // Update Hindi field
                        }
                      }}
                      onBlur={handleBlur}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Grid>
                  <Grid container item sm={12} lg={4}>
                    <TextField
                      value={values.phoneNumberHindi}
                      type="tel"
                      name="phoneNumberHindi"
                      fullWidth
                      placeholder="फोन नंबर दर्ज करें"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 10,

                        inputMode: "numeric", // Hints mobile devices to use numeric keyboard
                        pattern: "[0-9]*", // Ensures only numbers
                      }}
                      FormHelperTextProps={{
                        style: { marginLeft: "0px" },
                      }}
                      sx={style.textfield}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
                      }}
                      error={
                        touched.phoneNumberHindi &&
                        Boolean(errors.phoneNumberHindi)
                      }
                      helperText={
                        touched.phoneNumberHindi && errors.phoneNumberHindi
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  alignItems={"center"}
                  mt={2}
                  sx={{
                    gap: { sm: 1, lg: 6 },
                    justifyContent: { sm: "flex-start", md: "center" },
                  }}
                >
                  <Grid container item sm={12} lg={2}>
                    <label
                      style={{
                        whiteSpace: "pre-line" ,
                      }}
                    >
                      Email (ईमेल):
                    </label>
                  </Grid>
                  <Grid container item sm={12} lg={4}>
                    <TextField
                      value={values.email}
                      type="email"
                      name="email"
                      fullWidth
                      placeholder="Enter email address"
                      variant="outlined"
                      id="outlined-basic"
                      inputProps={{
                        maxLength: 80,
                      }}
                      sx={style.textfield}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      disabled={isLoading}
                      onChange={async (e) => {
                        const { name, value } = e.target;
                        handleChange(e); // Update Formik value
                        if (name === "email") {
                          // const transliteratedText = await transliterateToHindi(
                          //   value
                          // );
                          setFieldValue("emailHindi", value); // Update Hindi field
                        }
                      }}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid container item sm={12} lg={4}>
                    <TextField
                      value={values.emailHindi}
                      type="email"
                      name="emailHindi"
                      fullWidth
                      placeholder="ईमेल पता दर्ज करें"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 80,
                      }}
                      FormHelperTextProps={{
                        style: { marginLeft: "0px" },
                      }}
                      sx={style.textfield}
                      error={touched.emailHindi && Boolean(errors.emailHindi)}
                      helperText={touched.emailHindi && errors.emailHindi}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  alignItems={"center"}
                  mt={2}
                  sx={{
                    gap: { sm: 1, lg: 6 },
                    justifyContent: { sm: "flex-start", md: "center" },
                  }}
                >
                  <Grid container item sm={12} lg={2}>
                    <label
                      style={{
                        whiteSpace: "pre-line" ,
                      }}
                    >
                      State (राज्य):
                    </label>
                  </Grid>

                  {/* English Select */}
                  <Grid container item sm={12} lg={4}>
                  <Select
  value={values.state}
  name="state"
  fullWidth
  id="outlined-basic"
  placeholder="Select State"
  disabled={isLoading}
  onChange={(e) => {
    const { value } = e.target;
    setSelectedState(value);
    setSelectedCity("");
    setSelectedDistrict("");
    setFieldValue("state", value);
  }}
  onBlur={handleBlur}
  error={touched.state && Boolean(errors.state)}
  displayEmpty
  sx={style.select}
>
  <MenuItem value="" disabled>
    Select State
  </MenuItem>
  {statesData.map((state) => (
    <MenuItem key={state.state} value={state.state}>
      {state.state}
    </MenuItem>
  ))}
</Select>

                  </Grid>

                  {/* Hindi Select */}
                  <Grid container item sm={12} lg={4}>
                    <Select
                      value={values.stateHindi}
                      name="stateHindi"
                      fullWidth
                      variant="outlined"
                      onChange={(e) => {
                        const { value } = e.target;
                        setSelectedStateHindi(value);
                        // setSelectedCity("");
                        setSelectedCityHindi("");
                        // setSelectedDistrict("");
                        setSelectedDistrictHindi("");
                        setFieldValue("stateHindi", value);
                      }}
                      onBlur={handleBlur}
                      sx={style.select}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      error={touched.stateHindi && Boolean(errors.stateHindi)}
                      helperText={touched.stateHindi && errors.stateHindi}
                    >
                      <MenuItem value="" disabled>
                        राज्य चुनें
                      </MenuItem>
                      {statesData.map((state) => (
                        <MenuItem
                          key={state.stateHindi}
                          value={state.stateHindi}
                        >
                          {state.stateHindi}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  alignItems={"center"}
                  mt={2}
                  sx={{
                    gap: { sm: 1, lg: 6 },
                    justifyContent: { sm: "flex-start", md: "center" },
                  }}
                >
                  <Grid container item sm={12} lg={2}>
                    <label
                      style={{
                        whiteSpace: "pre-line" ,
                      }}
                    >
                      City (शहर):
                    </label>
                  </Grid>

                  {/* English Select */}
                  <Grid container item sm={12} lg={4}>
                    <Select
                      value={values.city}
                      name="city"
                      fullWidth
                      variant="outlined"
                      id="outlined-basic"
                      sx={style.select}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      disabled={!values.state}
                      onChange={(e) => {
                        const { value } = e.target;
                        setSelectedCity(value);
                        // setSelectedCityHindi("");
                        setSelectedDistrict("");
                        // setSelectedDistrictHindi("");
                        setFieldValue("city", value);
                      }}
                      onBlur={handleBlur}
                      error={touched.city && Boolean(errors.city)}
                      helperText={touched.city && errors.city}
                    >
                      <MenuItem value="" disabled>
                        Select City
                      </MenuItem>
                      {getCities(values.state).map((city) => (
                        <MenuItem key={city.city} value={city.city}>
                          {city.city}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid container item sm={12} lg={4}>
                    <Select
                      value={values.cityHindi}
                      fullWidth
                      name="cityHindi"
                      onChange={(e) => {
                        const { value } = e.target;
                        setSelectedCityHindi(value);
                        // setSelectedDistrict("");
                        setSelectedDistrictHindi("");
                        setFieldValue("cityHindi", value);
                      }}
                      onBlur={handleBlur}
                      disabled={!values.stateHindi}
                      sx={style.select}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      error={touched.cityHindi && Boolean(errors.cityHindi)}
                      helperText={touched.cityHindi && errors.cityHindi}
                    >
                      <MenuItem value="" disabled>
                        शहर चुनें
                      </MenuItem>
                      {getCitiesHindi(values.stateHindi).map((city) => (
                        <MenuItem key={city.cityHindi} value={city.cityHindi}>
                          {city.cityHindi}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  alignItems={"center"}
                  mt={2}
                  sx={{
                    gap: { sm: 1, lg: 6 },
                    justifyContent: { sm: "flex-start", md: "center" },
                  }}
                >
                  <Grid container item sm={12} lg={2}>
                    <label
                      style={{
                        whiteSpace: "pre-line" ,
                      }}
                    >
                      District (जिला):
                    </label>
                  </Grid>

                  {/* English Select */}
                  <Grid container item sm={12} lg={4}>
                    <Select
                      fullWidth
                      value={values.district}
                      name="district"
                      onChange={(e) => {
                        const { value } = e.target;
                        setSelectedDistrict(value);
                        // setSelectedDistrictHindi("");
                        setFieldValue("district", value);
                      }}
                      variant="outlined"
                      id="outlined-basic"
                      sx={style.select}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      onBlur={handleBlur}
                      error={touched.district && Boolean(errors.district)}
                      disabled={!values.city}
                      helperText={touched.district && errors.district}
                    >
                      <MenuItem value="" disabled>
                        Select District
                      </MenuItem>
                      {getDistricts(values.city).map((district) => (
                        <MenuItem
                          key={district.district}
                          value={district.district}
                        >
                          {district.district}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  {/* Hindi Select */}
                  <Grid container item sm={12} lg={4}>
                    <Select
                      fullWidth
                      value={values.districtHindi}
                      name="districtHindi"
                      onChange={(e) => {
                        const { value } = e.target;
                        setSelectedDistrictHindi(value);
                        setFieldValue("districtHindi", value);
                      }}
                      onBlur={handleBlur}
                      disabled={!values.cityHindi}
                      sx={style.select}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      error={
                        touched.districtHindi && Boolean(errors.districtHindi)
                      }
                      helperText={touched.districtHindi && errors.districtHindi}
                    >
                      <MenuItem value="" disabled>
                        जिला चुनें
                      </MenuItem>
                      {getDistrictsHindi(values.cityHindi).map((district) => (
                        <MenuItem
                          key={district.districtHindi}
                          value={district.districtHindi}
                        >
                          {district.districtHindi}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  alignItems={"center"}
                  mt={2}
                  sx={{
                    gap: { sm: 1, lg: 6 },
                    justifyContent: { sm: "flex-start", md: "center" },
                  }}
                >
                  <Grid container item sm={12} lg={2}>
                    <label
                      style={{
                        whiteSpace: "pre-line" ,
                      }}
                    >
                      Address (पता):
                    </label>
                  </Grid>
                  <Grid container item sm={12} lg={4}>
                    <TextField
                      value={values.address}
                      type="text"
                      name="address"
                      fullWidth
                      placeholder="Enter address"
                      variant="outlined"
                      id="outlined-basic"
                      inputProps={{
                        maxLength: 80,
                      }}
                      sx={style.textfield}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      disabled={isLoading}
                      onChange={async (e) => {
                        const { name, value } = e.target;
                        handleChange(e); // Update Formik value
                        if (name === "address") {
                          const transliteratedText = await transliterateToHindi(
                            value
                          );
                          setFieldValue("addressHindi", transliteratedText); // Update Hindi field
                        }
                      }}
                      onBlur={handleBlur}
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                    />
                  </Grid>

                  {/* Hindi Field for Address */}
                  <Grid container item sm={12} lg={4}>
                    <TextField
                      value={values.addressHindi}
                      type="text"
                      name="addressHindi"
                      fullWidth
                      placeholder="पता दर्ज करें"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 80,
                      }}
                      sx={style.textfield}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      error={
                        touched.addressHindi && Boolean(errors.addressHindi)
                      }
                      helperText={touched.addressHindi && errors.addressHindi}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={"center"}
                  mt={6}
                  mb={8}
                  sx={{ gap: { xs: 4, md: 10 } }}
                >
                  <Grid item container xs={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ background: "gray" }}
                      onClick={() => {
                        navigate(-1);
                      }}
                      disabled={isLoading}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item container xs={2}>
  <Button
    variant="contained"
    fullWidth
    style={{ background: "#F83629" }}
    type="submit"
  >
    {submitload ? (
      <CircularProgress size={24} style={{ color: "white" }} />
    ) : (
      "UPDATE"
    )}
  </Button>
</Grid>

                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default EditCareGiver;
