import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Grid,
  IconButton,
  Typography,
  Box,
  Divider,
  Modal,
} from "@mui/material";
import DropDown from "./common/Dropdown";
import axios from "axios";
import { ApiConfig } from "../config/ApiConfig";
import toast from "react-hot-toast";

const ReassignCareGiverPopUp = ({
  open,
  setOpen,
  currentBusinessOwnerData,
  ashaWorkerId,
  setUpdateList,
}) => {
  console.log("currentBusinessOwnerData: ", currentBusinessOwnerData);
  const [caregivers, setCaregivers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedCaregiverIds, setSelectedCaregiverIds] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState([]);
  console.log("selectedOption: ", caregivers);

  const options = caregivers.map((caregiver) => ({
    label: caregiver.fullName_en,
    id: caregiver.id,
    caregiverDetail: caregiver,
  }));

  const handleClose = () => {
    setSelectedOption([]);
    setOpen(false);
  };
  const handleCaregiverChange = (newSelectedCaregivers) => {
    setSelectedOption(newSelectedCaregivers);
    const selectedIds = newSelectedCaregivers.map((caregiver) => caregiver.id);
    setSelectedCaregiverIds(selectedIds);
  };

  const listAllcareGiver = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.listAllUnassignedCaregivers,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        // params: {
        //   userType: "CAREGIVER"
        // },
      });

      if (res?.data?.responseCode === 200) {
        console.log(res?.data?.result?.userList, "ghjghjhj");
        setCaregivers(res?.data?.result?.userList);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };

  const reassigncaregiver = async () => {
    if (selectedCaregiverIds.length === 0) {
      toast.error("Please select at least one caregiver.");
      return;
    }

    setIsLoading(true);

    try {
      const res = await axios({
        method: "PUT",
        url: ApiConfig.assignMultipleCaregiversToAshaWorker,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        data: {
          ashaWorkerId: ashaWorkerId,

          caregiverIds: selectedCaregiverIds.map((id) => ({ id })),
        },
      });

      if (res?.data?.responseCode === 200) {
        toast.success("ASHA worker assigned successfully.");
        setUpdateList(true);
        handleClose();
      } else {
        toast.error(res?.data?.responseMessage || "Something went wrong.");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.responseMessage ||
          "An error occurred. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listAllcareGiver();
  }, []);

  const renderCaregiverCard = (selectedOption, index) => (
    <Grid
    sx={{
      p: 2,
      mb:2,
      borderRadius: 2,
      boxShadow: 2,
      bgcolor: "background.paper",
      display: "flex",
      flexDirection: { xs: "column", lg: "row" },
      alignItems: "center",
      gap: 2,
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "scale(1.01)",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
      },
    }}
    >
      <Avatar
        sx={{
          height: { xs: 80, sm: 100, md: 120 }, // Responsive size for Avatar
          width: { xs: 80, sm: 100, md: 120 },
          mb: 2,
          mx: "auto", // Center avatar horizontally
        }}
        src={selectedOption?.caregiverDetail?.profileUrl}
      />
      <Box>
        <Typography
          variant="h5"
          sx={{
            color: "#2A73E0",
            fontWeight: "bold",
            textTransform: "uppercase",
            letterSpacing: 1,
            mb: 2,
          }}
        >
         {selectedOption?.caregiverDetail?.fullName_en}
        </Typography>
        {[
         
          { label: "Email", value: selectedOption?.caregiverDetail?.email_en },
          {
            label: "Phone No.",
            value: selectedOption?.caregiverDetail?.mobileNumber_en,
          },
          { label: "Address", value: selectedOption?.caregiverDetail?.address_en },
        ].map((detail, index) => (
          <Box key={index} sx={{ display: "flex", mb: 1 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", minWidth: 120, color: "text.primary" }}
            >
              {detail.label}:
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {detail.value || "N/A"}
            </Typography>
          </Box>
        ))}
      </Box>


    </Grid>
  );

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
      }}
    >
      <Box
        sx={{
          bgcolor: "#fff",
          width: "100%",
          maxWidth: 600,
          maxHeight: "90vh",
          p: 4,
          borderRadius: 2,
          boxShadow: 2,
          overflowY: "auto",
        }}
      >
        <Typography variant="h5" mb={3}>
          Assign New Caregiver
        </Typography>

        <Grid container spacing={3} alignItems="center" fullWidth>
          {/* Dropdown Section */}
          <Grid item xs={12}>
            <DropDown
              label="Select Caregiver"
              options={options}
              value={selectedOption}
              onChange={handleCaregiverChange}
              multiple={true}
            />
          </Grid>
        </Grid>
        {selectedOption && (
  <Box
    mt={4}
    p={2}
    sx={{
      maxHeight: "300px", // Limit the height of the card container
      overflowY: "auto",  // Enable only vertical scrolling
      overflowX: "hidden", // Disable horizontal scrolling
      scrollbarWidth: "thin", // Make scrollbar thin (for Firefox)
      "&::-webkit-scrollbar": {
        width: "6px", // Width of the scrollbar
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888", // Scrollbar thumb color
        borderRadius: "4px", // Round the corners
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555", // Thumb color on hover
      },
    }}
  >
    {selectedOption?.map((caregiver, index) =>
      renderCaregiverCard(caregiver, index)
    )}
  </Box>
)}



        <Divider sx={{ my: 4 }} />

        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              onClick={reassigncaregiver}
              variant="contained"
              sx={{
                bgcolor: "#fe352c",
                fontSize: "0.9rem",
                px: 3, // Horizontal padding
                py: 1, // Vertical padding
                whiteSpace: "pre-line" , // Prevents text wrapping
                borderRadius: 2, // Rounded button
                minWidth: 150, // Ensures buttons are of consistent size
                textAlign: "center",
              }}
              disabled={isLoading}
            >
              Assign Caregivers
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                bgcolor: "gray",
                fontSize: "0.9rem",
                px: 3,
                py: 1,
                whiteSpace: "pre-line" ,
                borderRadius: 2,
                minWidth: 150,
                textAlign: "center",
              }}
              disabled={isLoading}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ReassignCareGiverPopUp;
