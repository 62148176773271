import styled from "@emotion/styled";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Formik, Form } from "formik";
import React, { useState, useRef, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { postAPIHandlerWithoutToken } from "../../config/service";
import DialogCommon from "../../component/common/Dialogs";
import ReCAPTCHA from "react-google-recaptcha";

const LoginWrapper = styled("div")({
  height: "100vh",
  width: "40%",
  overflow: "hidden",
  marginTop: "160px",
  // marginRight: "100px",
  "@media(max-width:1280px)": {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    marginRight: "0px",
  },
  "@media(max-width:640px)": {
    marginRight: "0px",
  },
});

const FormContainer = styled(Form)({
  marginTop: "40px",
  display: "flex",
  flexDirection: "column",

  "@media(max-width:640px)": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const FormTextField = styled(TextField)({
  height: "60px!important",
  marginTop: "8px",
  width: "100%",
  "@media(max-width:1024px)": {
    width: "70vw !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    marginTop: "2px",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#1D60B3",
    },
  },
  marginBottom: "14px",
});

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required("Email is required.")
    .email("Enter valid email.")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Enter valid email."),
  // .max(79, "Max 80 character are allowed."),
  password: Yup.string()
    .trim()
    .required("Password is required.")
    .min(6, "Minimum 6 digit required."),
  // .max(39, "Maximum 40 character allowed."),
});

const Login = () => {
  const navigate = useNavigate();
  const [isHidden, setIsHidden] = useState(true);
  const [isRememberMeEnable, setisRememberMeEnable] = useState(
    localStorage.getItem("password") != null
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState(
    parseInt(localStorage.getItem("failedAttempts")) || 0
  );

  const [captchaVerified, setCaptchaVerified] = useState(false);
  const formikRef = useRef();

  const handleClose = () => {
    setOpenDialog(false);
    setIsHidden(true);
  };

  const submitHandler = async (values) => {
    if (failedAttempts >= 3 && !captchaVerified) {
      toast.error("Please verify reCAPTCHA.");
      return;
    }
    const payLoadToSend = {
      email: values.email?.toLowerCase()?.trim(),
      password: values.password,
    };
    try {
      const response = await postAPIHandlerWithoutToken({
        endPoint: "adminLogin",
        dataToSend: payLoadToSend,
      });
      if (response) {
        console.log(response, "response");
        if (response?.data?.responseCode === 200) {
          sessionStorage.setItem(
            "authToken",
            response?.data?.result?.authToken
          );
          sessionStorage.setItem("id", response?.data?.result?.id);
          if (isRememberMeEnable) {
            localStorage.setItem("email", values.email);
            localStorage.setItem("password", values.password);
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
          }
          localStorage.setItem("failedAttempts", "0");
          setFailedAttempts(0);
          navigate("/dashboard", { replace: true });
          toast.success("Logged in successfully");
        } else {
          const newFailedAttempts = failedAttempts + 1;
          localStorage.setItem("failedAttempts", newFailedAttempts.toString());
          setFailedAttempts(newFailedAttempts);
          setOpenDialog(true);
          if (newFailedAttempts >= 3) {
            toast.warn("Please verify reCAPTCHA.");
            setCaptchaVerified(false);
          }
        }
      }
    } catch (error) {
      setOpenDialog(true);
      console.log(error, "error");
    }
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    }
  };

  return (
    <LoginWrapper>
      <Typography variant="h4" align="center">
        Administrator Login
      </Typography>
      <Typography variant="subtitle1" align="center">
        Welcome back, you've been missed!
      </Typography>

      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          email: localStorage.getItem("email") || "",
          password: localStorage.getItem("password") || "",
        }}
        validationSchema={LoginSchema}
        onSubmit={submitHandler}
      >
        {({ errors, touched, handleBlur, handleChange, values }) => (
          <FormContainer>
            <FormControl>
              <label>Email</label>
              <FormTextField
                value={values?.email}
                inputProps={{
                  maxLength: 80,
                  style: {
                    height: "20px",
                    marginBottom: "0px",
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
                variant="outlined"
                name="email"
                onChange={(e) => {
                  handleChange(e);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                onBlur={handleBlur}
                placeholder="Enter your email address"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <label>Password</label>
              <FormTextField
                value={values?.password}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
                inputProps={{
                  maxLength: 40,
                  style: {
                    height: "20px",
                  },
                }}
                type={isHidden ? "password" : "text"}
                variant="outlined"
                name="password"
                placeholder="Enter password"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setIsHidden((p) => !p)}>
                        {isHidden ? (
                          <VisibilityOffIcon />
                        ) : (
                          <RemoveRedEyeIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            {failedAttempts >= 3 && (
              <ReCAPTCHA
                sitekey= {process.env.REACT_APP_CAPTCHA_KEY}
                onChange={handleCaptchaChange}
              />
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                "@media(max-width:1024px)": {
                  width: "70vw !important",
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRememberMeEnable}
                    style={{ color: "#1D60B3", paddingRight: "3px" }}
                    onChange={() => setisRememberMeEnable((p) => !p)}
                  />
                }
                label={
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: { xs: "12px", md: "16px" },
                      fontWeight: "500",
                    }}
                  >
                    Remember me
                  </Typography>
                }
              />
              <Typography
                component={Button}
                variant="subtitle2"
                color={"#1D60B3"}
                sx={{
                  fontSize: { xs: "12px", md: "16px" },
                  textTransform: "none",
                  textDecoration: "underline",
                  fontWeight: "500",
                }}
                onClick={() => {
                  navigate("/forget");
                }}
              >
                Forgot Password?
              </Typography>
            </Box>

            <Box
              mt={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                disabled={isLoading}
                style={{
                  background:
                    "linear-gradient(98deg, #2A73E0 -1.68%, #1D60B3 103.45%)",
                  padding: "8px 50px",
                  marginTop: "10px",
                  textTransform: "none",
                }}
               
                variant="contained"
                type="submit"
                loading={isLoading}
              >
                Login Now
              </Button>
            </Box>

            <Box
              mt={2}
              sx={{
                display: "flex",
                justifyContent: "center", // Center the links
                gap: "12px", // Space between the links
                alignItems: "center", // Align items vertically in the center
                width: "100%", // Ensure it takes full width to center properly
              }}
            >
              <Typography
                component="a"
                href="/termsandconditions"
                target="_blank"
                variant="subtitle2"
                color={"#1D60B3"}
                sx={{
                  fontSize: { xs: "12px", md: "16px" },
                  textDecoration: "underline",
                  fontWeight: "500",
                }}
              >
                Terms & Conditions
              </Typography>
              <Typography
                component="a"
                href="/privacypolicy"
                target="_blank"
                variant="subtitle2"
                color={"#1D60B3"}
                sx={{
                  fontSize: { xs: "12px", md: "16px" },
                  textDecoration: "underline",
                  fontWeight: "500",
                }}
              >
                Privacy Policy
              </Typography>
            </Box>
          </FormContainer>
        )}
      </Formik>

      <DialogCommon
        handleClose={handleClose}
        open={openDialog}
        type="loginAuthCredentialsNotMatch"
      />
    </LoginWrapper>
  );
};

export default Login;
